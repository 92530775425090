<template>
  <div
    class="modal fade"
    id="result"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-fullscreen">
      <div class="modal-content"> 
          <div class="body-container">
            <div class="row justify-content-between">
                <div class="col pt-3"  v-if="result">
                    <button
                        type="button"
                        data-bs-dismiss="modal"
                        class="back-button btn"
                        @click="rescan"
                    >
                        &#60; Back to Scan
                    </button>
                </div>
                <div class="col text-center">
                    <img src="./assets/lify.svg" alt="">
                </div>
                <div class="col text-end pt-3">
                    <button
                        type="button"
                        data-bs-dismiss="modal"
                        class="back-button btn"
                        @click="newScan"
                    >
                        Start a new scan &#62; 
                    </button>
                </div>
            </div>
            <div class="content-title text-center">
                <p>
                    Top Wellness Blends Recommendations For You
                </p>
                <p>
                    POWERED BY <b class="fw-bolder">LIFY WELLNESS</b>
                </p>
            </div>
            <div class="result-box row justify-content-center">
                <div class="column" v-for="tea of recommend" :key="tea">
                    <result-box :name="tea" :content="content[tea]" />
                </div>
            </div>
            <div class="row justify-content-center subscribe-container">
                <div class="subscribe">
                    <div class="row">
                        <div class="col-9 subscribe-col">
                            <div class="subscribe-title">
                                Join the Lify community!
                            </div>
                            <div class="subscribe-subtitle">
                                Submit your email to receive our latest updates
                            </div>
                            <div class="input-group subscribe-form">
                                <input type="email" class="form-control subscribe-input" id="subscriptionEmail">
                                <button class="btn subscribe-button" type="button" id="button-addon2" v-on:click="subscribe">Subscribe</button>
                            </div>
                            <div class="px-1 text-success pt-1" v-if="subscribed == 'success'">
                                You have successfully subscribed to us!
                            </div>
                            <div class="px-1 text-danger pt-1" v-else-if="invalidEmail">
                                Please enter a valid email
                            </div>
                            <p class="contact-info">
                                Stay tuned and get social with us <b>@LIFYWELLNESS</b> (Facebook/Instagram)
                            </p>
                        </div>
                        <div class="col-3 barcode">
                            <img src="./assets/barcode.svg" alt="">
                            <div class="barcode-text">
                                SCAN TO EXPLORE
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ResultBox from "./result-box.vue";
import { newUserManager } from "../../user/company-user-manager";

export default {
  props: {
    showResult: Boolean,
    result: Object
  },
  emits: ['removeProfile'],
  components: {
    ResultBox
  },
  methods: {
    newScan() {
        this.$emit('removeProfile');
    }, 
    subscribe() {
        let email = document.getElementById('subscriptionEmail').value;
        const validateEmail = (email) => {
            //eslint-disable-next-line
            return email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        };
        if (validateEmail(email)) {
            this.invalidEmail = false;
            let currentUser = newUserManager.currentUser;
            this.subscribed = currentUser.uploadSubscriberEmail(email) ? 'success' : 'failed';
        } else if (validateEmail(email) == null) {
            this.invalidEmail = true;
            this.subscribed = 'failed';
        }
    }
  },
  computed: {
    recommend() {
        let teas = [];
        if(this.result == undefined) {
            return teas;
        }
        this.result.map(group => {
            teas.push(this.recommendations[group]);
        })
        const sortByFrequency = (array) => {
            var frequency = {};

            array.forEach(function(value) { frequency[value] = 0; });

            var uniques = array.filter(function(value) {
                return ++frequency[value] == 1;
            });

            return uniques.sort(function(a, b) {
                return frequency[b] - frequency[a];
            });
        } 
        teas = teas.flat();
        teas = sortByFrequency(teas);  
        if (teas.length > 4) {
            teas = teas.slice(0,4);
        }
        return teas;
    },
    landscape() {
        if (window.innerWidth > 1024) {
            return true;
        }
        else {
            return false;
        }
    }
  },
  data() {
    return {
        subscribed: false,
        invalidEmail: false,
        content: {
            boost: {
                title: "Boost",
                subtitle: "Ginseng Immunity Support",
                description: "A preciously sourced and curated blend with the primary elements of American ginseng and premium grade Tieguanyin Tea. These elements help to increase both physical and mental efficiency.",
                image: "./assets/boost.jpg"
            },
            sooth: {
                title: "Sooth",
                subtitle: "Monk Fruit Prunella Blend",
                description: "A soothing blend to cool the body and help nourish and stabilise numerous internal organs.",
                image: "./assets/sooth.jpg"
            },
            revive: {
                title: "Revive",
                subtitle: "Reishi Vitality Support",
                description: "A reviving blend using the precious Lingzhi to nourish 'yin', invigorate vital energy and restore the body's immune system.",
                image: "./assets/revive.jpg"
            },
            sleep: {
                title: "Sleep",
                subtitle: "Quality Sleep with Rooibos Chamomile",
                note: "Caffeine-free",
                description: "A comforting blend of caffeine-free Rooibos and calming Chamomile, best to be taken in the evening for an enhanced restful sleep.",
                image: "./assets/sleep.jpg"
            },
            teatox: {
                title: "Teatox",
                subtitle: "Detoxifying Infusion",
                note: "Caffeine-free",
                description: "A caffeine-free blend of herbs designed to naturally assist your body cleanse and detox, leaving you feel refreshed and healthy.",
                image: "./assets/teatox.jpg"
            },
            energy: {
                title: "Energy",
                subtitle: "Turmeric Power Up Body Digestive Support",
                description: "An uplifting blend of black tea with herbs and spices that are traditionally used for reducing inflammation, improving circulation and giving you an energy boost.",
                image: "./assets/energy.jpg"
            },
            sunrise: {
                title: "Sunrise",
                subtitle: "Hibiscus Heart Warming (Passion Fruit)",
                note: "Caffeine-free",
                description: "A delightful blend composed of white hibiscus blossoms, rose, and herbs designed to make you feel refreshed and renewed.",
                image: "./assets/sunrise.jpg"
            },
            sunset: {
                title: "Sunset",
                subtitle: "Hibiscus Stress Relief (Blood Orange)",
                note: "Caffeine-free",
                description: "A declicious blend of premium herbs with hibiscus flowers to create a tea high in antioxidants to renew and leave your bod feeling fresh.",
                image: "./assets/sunset.jpg"
            },
            garden: {
                title: "Garden",
                subtitle: "Lavender Raspberry White Tea",
                description: "Premium white tea base blended with relaxing lavender and antioxidant rich apples for a delicious tea.",
                image: "./assets/garden.jpg"
            },
        },
        recommendations: {
            energising: ['boost', 'energy'],
            detox: ['revive', 'teatox', 'sooth'],
            'stress-relief': ['sunrise', 'sunset', 'garden'],
            immunity: ['boost', 'revive', 'energy']
        }
    }
  }
};
</script>

<style scoped>
.modal {
  height: 98%;
  width: 98%;
  left: 1%;
  top: auto;
  bottom: 1%;
  border-radius: 10px;
}
.modal-dialog {
  width: 100%;
  height: 100%;
}
.modal-close-button {
  position: fixed;
  top: 4.5%;
  right: 2%;
}
/* Modal Content/Box */
.modal-content {
  position: grid;
  margin: 0% auto;
  padding: 5px;
  min-height: 95%;
  background-color: #FAEFE3;
  min-width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}
.modal-header {
  display: flex;
  height: 10%;
  justify-content: center;
  font-size: xx-large;
}
.modal-body {
  display: grid;
  width: 100%;
  justify-content: center;
  padding: 2px;
  margin: 2px auto;
}
.content-title {
  font-family: Montserrat;
  font-size: x-large;
  padding-top: 60px;
  padding-bottom: 60px;
}
.center-item {
  display: grid;
  justify-content: center;
}
.body-container {
    margin: 20px;
}
.subscribe-container {
    margin-top: 60px;
}
.subscribe {
    box-sizing: border-box;
    font-family: 'Montserrat';
    display: inline-block;
    width: 636px;
    height: 268px;
    background: #FE805C;
    border-radius: 24px;
    padding: 0 30px;
}
.subscribe-title {
    margin-bottom: 5px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 30px;

    color: #FFFFFF;
}
.subscribe-subtitle {
    margin-bottom: 25px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;

    color: #FFFFFF;
}
.subscribe-form {
    border-radius: 90px 0 0 90px;
}
.subscribe-input {
    border-radius: 90px 0 0 90px;
    border-bottom: none;
    border-left: none;
    border-top: none;
}
.subscribe-button {
    height: 51px;
    background-color: white;
    border-radius: 0px 90px 90px 0;
    border-left: #12121F;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    display: flex;
    align-items: center;

    color: #12121F;
}
.subscribe-col {
    padding: 26px;
}
.contact-info {
    margin-top: 22.4px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;

    color: #FFFFFF;
}
.back-button {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
}
.barcode {
    padding-top: 26px;
}
.barcode-text {
    padding-top: 10px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    text-align: left;

    color: #FFFFFF;

}
.column {
    flex: 0 0 auto;
    width: 25%;
}
@media (max-width: 1024px) {
    .result-box {
        padding: 0 168px;
        --bs-gutter-x: 52px;
        --bs-gutter-y: 63px;
    }
    .column {
        flex: 0 0 auto;
        width: 50%;
    }
}
@media (max-width: 834px) {
    .result-box {
        padding: 0 50px;
        --bs-gutter-x: 52px;
        --bs-gutter-y: 63px;
    }
}

@media (max-width: 600px) {
    .content-title {
        font-size: 22px;
    }
    .column {
        padding: 0 10px;
    }
}
</style>


