<template>
    <div class="hud-container col-2" v-if="scanParameters && conditions">
        <!-- <div v-for="n in 4">
        </div> -->
        <div class="quality-box">
            <div class="title">
                <div class="text">
                    {{ t('message.scan_quality') }} :
                </div>
                <div class="star-group">
                    <span v-for="condition in conditions" :key="condition">
                        <img :src="imgSrc(condition)" class="stars"/> 
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useI18n } from "vue-i18n";

export default
  {
    name: 'signal-quality-box',
    props: {
        scanParameters: null,
    },
    setup() {
        const { t, locale } = useI18n();
        return { t, locale }
    },
    mounted() {
        this.getConditions();
    },
    data() {
        return {
            conditions: null,
        }
    },
    watch: {
        scanParameters() {
            this.getConditions();
        }
    },
    methods: {
        imgSrc(condition) {
            if (condition) { return require("./assets/colour-star.svg"); }
            else { return require("./assets/grey-star.svg"); }
        },
        getConditions() {
            if (this.scanParameters !== null) {
                let numberOfTotalStars = 5;
                let numberOfGoodStars = Math.floor(this.scanParameters.signalQuality / 100 * numberOfTotalStars);
                let numberOfBadStars = numberOfTotalStars - numberOfGoodStars;
                let conditions = Array()
                for (let i=0; i<numberOfGoodStars; i++) { conditions.push(true) }
                for (let i=0; i<numberOfBadStars; i++) { conditions.push(false) }
                this.conditions = conditions
            } else {
                this.conditions = null;
            }
        }
    },
    computed: {
        parameters(){
            if (this.scanParameters && this.scanParameters.signalQuality) {
                return this.scanParameters.signalQuality.toString()
            }
            return "null"
        },
    }
}
</script>

<style scoped>
.stars {
    max-width: 40px;
    padding-left: 8.8px;
    margin-top: -3px;
}
.star-group {
    display: inline-block;
}
.hud-container {
    display: flex;
    width: 100%;
    justify-content: center;
    position: absolute;
    top: 200px;
}
.quality-box {
    width: 330px;
    height: 51px;
    background-color: white;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}
.title {
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}
.data {
    margin-left: auto;
    margin-right: auto;
    width: max-content;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 30px;
    display: flex;
    align-items: center;
    text-align: center;
    max-width: 100%;
    
    color: #FFFFFF;
}
.text {
    display: inline-block;
    margin: 0;
    margin-right: 10px;
}

@media (max-width: 1024px) {
    .hud-container {
        top: 175px;
    }
}

@media (max-width: 600px) {
    .hud-container {
        top: 95px;
    }
    .stars {
        max-width: 22px;
        padding-left: 3px;
    }
    .quality-box {
        width: 290px;
        height: 43px;
    }
    .title {
        font-size: 14px;
    }
}
</style>


