const locales = {
  En: {
    page: {
      dashboard: 'Dashboard',
      settings: 'Settings',
      help: 'Help',
      logout: 'Logout',
      coming_soon: 'Stay tuned!<br>We are Coming Soon...',
    },
    guest_signin: 'Continue as Guest',
    logout_prompt: 'Are you sure you want to log out?',
    instruction: {
      stay_still: 'Scanning in progress, stay still.',
      count_down1: 'Scanning starting in ',
      count_down2: ', stay still'
    },
    vital_signs: {
      heartRate: {
        title: 'Heart Rate',
        body: [
          "Your heart rate is {value} beats per minute, which is within the {condition} heart rate range. For most healthy adult women and men, resting heart rates range from 60 to 100 beats per minute.",
          "The interesting fact about heart rate is your heartpumps about 1.5 \
          gallons of blood every minute. Over the course of aday, that adds up to over 2,000 gallons. The average heartbeat of a woman is about 8 beats a minute \
          faster than a man's heartbeat.",
          'You get the most benefits when you exercise in your "target heart rate zone." Usually, this is when your heart rate (pulse) is 60% to 80% of your maximum.',
        ],
        excellent: 'Keep it up with your healthy lifestyle!',
        good: "You're doing awesome. Remember to do exercises and manage stress to maintain this good state.",
        poor: 'Make sure you are not scanning after a workout session! Or visit a doctor to prevent underlying diseases.',
      },
      hrvIbi: {
        title: 'HR Variability IBI',
        body: [
          "Your IBI is in {condition} condition which is around {value} ms, although it varies from person-to-person, which depends on exercising, lifestyle and biological factors.",
          "One interesting fact about IBI is that it is actually controlled by the sympathetic nervous \
          systems, which tells the heart to beat faster, and parasympathetic nervous system, which tells the heart to beat slower.",
          "Interbeat interval (IBI) is the time domain beat-to-beat variation of the electrocardiography (ECG). An optimal, usually higher level of HRV indicates the ability \
          to cope with changing environment,self-regulate, and therefore better overall wellbeing.",
        ],
        excellent: 'You are doing great!',
        good: "Remember to exercise regularly.",
        poor: 'Remember to exercise regularly and visit a doctor if any symptoms occur.',
      },
      hrvSdnn: {
        title: 'HR Variability SDNN',
        body: [
          "Your SDNN is in {condition} condition which is {value} ms, although it varies from person-to-person, which depends on exercising, lifestyle and biological factors.",
          "We can see a lot from HRV data. HRV reflects the regulation of autonomic balance, blood pressure, gas exchange, gut, heart, and vascular tone, which refers to the diameter of the blood vessels that regulate BP, and possibly facial muscles.",
          "The SD of the IBI (SDNN) is the most widely used measure of HRV. SDNN is a good indication for cardiac risk when recorded over a 24 h period. \
          SDNN values predict both morbidity and mortality. For example, patients with SDNN values under 50 ms had a 5.3 times higher risk of mortality \
          at follow-up than those with values over 100 ms.",
        ],
        excellent: 'Great job!',
        good: "Keep up the good work! Remember to sleep well and consistently.",
        poor: 'Remember to sleep early and workout regularly.',
      },
      respiratoryRate: {
        title: 'Breathing Rate',
        body: [
          "The number of breaths you take per minute is {value},  which is {condition}. Normal respiration rates for an adult \
          person at rest range from 12 to 16 breaths per minute. Normal respiration rates for an adult person at rest range from 12 to \
          16 breaths per minute.",
          "Please make sure not to check this vital sign right after exercises. \
          An Interesting fact about the respiratory system is you lose a lot of water by breathing. Humans exhale up to 17.5 milliliters of water per hour.",
          "Respiratory rate is a fundamental vital sign that is sensitive to different pathological conditions (e.g., adverse cardiac events, \
          pneumonia, and clinical deterioration) and stressors, including emotional stress, cognitive load, heat, cold, physical effort, and exercise-induced fatigue.",
        ],
        excellent: 'You are doing great!',
        good: "There you go! Remember to avoid exposure to pollutants to make it excellent.",
        poor: '',
      },
      spo2: {
        title: 'O2 Saturation',
        body: [
          "The percentage of oxygen in your blood is {value}%, which is {condition}. A normal level of oxygen is usually 95% or higher.",
          "SpO2, also known as oxygen saturation, is a measure of the amount of oxygen-carrying \
          hemoglobin in the blood relative to the amount of hemoglobin not carrying oxygen. The body needs there to be a certain level of oxygen in the blood or it will \
          not function as efficiently.",
          "Oxygen is tightly regulated within the body because hypoxemia can lead to many acute adverse effects on individual organ systems such as brain, heart, and kidneys.",
        ],
        excellent: 'You are doing great! Keep it up with your healthy lifestyle and exercise regularly!',
        good: "Remember to exercise regularly to keep the SpO2 level.",
        poor: "To enhance your health, you'd better have healthy diets. Blackberries, blueberries, and kidney beans are recommended for you.",
      },
      stress: {
        title: 'Stress Rating',
        body: [
          "Your stress rating is {value}, which is {condition}. Normal stress level is around 0-2",
          "Do you know that the stress level is controlled by the nervous system? When we are faced with danger, our central nervous systems switch into “fight or flight” mode. Our heart rates increase, blood vessels become narrower, and we become more mentally alert. These changes in our bodies prepare us for swift action, to either face the threat head-on, or run away.",
          "Stress not only affects your emotions, it is also closely related to your physical wellbeing. Stress will lead to physiological response \
          such as muscle aches and tightness as a natural defensive function to protect your body from injuries.",
        ],
        excellent: 'You are not stressed! Thank you for taking good care of your mental health and well-being!',
        good: "Remember to take a break once in a while and maintain this good state.",
        poor: 'Remember to talk to someone when you have too much on your plate. ',
      },
      temperature: {
        title: 'Temperature (Beta)',
        body: [
          "Your body temperature is now {value}°C, which falls into {condition} range.",
          "An interesting fact about temperature is that sometimes cooler skin can protect your brain. Doctors found out that lowering the body temperature right after cardiac arrest can reduce damage to the brain, and raises the chances that the person will recover.",
          "If your body temperature is above 39.4 ℃, seek your doctor as soon as possible",
        ],
        excellent: 'Remember to stay hydrated.',
        good: "You are doing a great job!. Remember to stay hydrated.",
        poor: 'Please seek medical advice!',
      },
      bloodPressure: {
        title: 'Blood Pressure (Beta)',
        body: [
          "Your blood pressure is now {value} mmHg which is {condition}. A normal blood pressure level is less than 120/80 mmHg.",
          "Usually, blood pressure starts to rise a few hours before you wake up. It continues to rise during the day\
          , peaking in midday. Blood pressure normally drops in the late afternoon and evening. Blood pressure is normally lower at night while you're sleeping",
          "A normal blood pressure level is less than 120/80 mmHg. Usually, blood pressure starts to rise a few hours before you wake up. It continues to rise during the \
          day, peaking in midday. Blood pressure normally drops in the late afternoon and evening. Blood pressure is normally lower at night while you're sleeping",
        ],
        excellent: 'Great job! Don’t let your emotions overwhelm you.',
        good: "Remember if you feel lightheaded, try to close your eyes and focus on your breathing.",
        poor: 'Meditate and forget about your worries for 5 minutes.',
      },
      bmi: {
        title: 'BMI',
        body: [
          "According to your height and weight, your BMI is {value} which is in {condition} range. For adults 20 years old and older, a healthy BMI ranges from 18.5 - 24.9.",
          "Do you know how to calculate your BMI? BMI = weight (kg) / height (m)^2",
          "If your BMI indicates you're overweight or obese, you may be at risk for certain health conditions. Those include diabetes, heart disease, \
          arthritis, and certain cancers. However, BMI can't be used to diagnose disease.",
        ],
        excellent: 'Well done!  Please continue to have a healthy diet and work out regularly.',
        good: "Remember to keep your BMI range within 18.5-24.9 by eating healthy and doing physical activities.",
        poor: '',
      },
      generalRisk: {
        title: 'CVD Risk (Beta)',
        body: [
          "Your CVD risk is now at {value}%, which falls into a {condition} risk of cardiovascular disease.",
          "Do you know that it is possible to have a broken heart? It’s called broken heart syndrome and it resembles heart attack symptoms.  A heart attack results from a cardiac condition, whereas broken heart syndrome is brought on by an increase in stress hormones from an emotional or physical stressful incident.",
          "If your CVD risk is above 15%, it indicates that you are at a high risk of cardiovascular disease. Cardiovascular diseases (CVDs) are a group of disorders of the heart and blood vessels, which include coronary heart disease, cerebrovascular disease, peripheral arterial disease, rheumatic heart disease, etc."
        ],
      },
      covidRisk: {
        title: 'Covid Risk (Beta)',
        body: [
          "Your Covid Risk is now at {value}%, which falls into a {condition} risk of covid risk.",
          "Do you know that the level of Covid risk is affected by many different interacting factors? They include age and existing health conditions such as heart disease, lung disease, diabetes, hypertension, and being overweight. [2]<br><br>The most common symptoms of COVID-19 include fever, dry cough, and fatigue. Some people may only have very mild or non-specific symptoms, while some may develop severe symptoms like shortness of breath, chest pain, or confusion. [3]",
          "If your covid risk is above [xx%] and you are feeling serious symptoms, try to seek immediate medical attention. If you feel mild symptoms, remain at home and self-isolate until you feel better."
        ],
      },
      facialSkinAge: {
        title: 'Facial Skin Age',
        body: [
          "Your facial skin age is {value}. Excellent! You are young and charming",
          "The interesting fact is that the first signs of aging start to become apparent on the surface of the skin from around the age of 25. ",
          "One of the most obvious indications of aging is skin changes. The visible signs of aging appear on your skin quickly. While healthy lifestyle choices and an effective skin care regimen can control some skin-related factors, many age-related traits are related to genetics. Below are the Seven Signs of Ageing:\
          <ul>\
            <li>\
              Fine lines and wrinkles. \
            </li>\
            <li>\
              Dullness of skin.\
            </li>\
            <li>\
              Uneven skin tone.\
            </li>\
            <li>\
              Dry skin.\
            </li>\
            <li>\
              Blotchiness and age spots. \
            </li>\
            <li>\
              Rough skin texture. \
            </li>\
            <li>\
              Visible pores.\
            </li>\
          </ul>\
          "
        ],
      },
      bloodSugar: {
        title:'Blood Sugar',
        body: [
          "To be completed.",
          "To be completed.",
          "To be completed."
        ],
      },
      bloodAlcohol: {
        title: 'Blood Alcohol',
        body: [
          "To be completed.",
          "To be completed.",
          "To be completed."
        ],
      },
      general_wellness: {
        title: 'General Wellness',
      },
      energy: {
        title: 'Energy',
      },
      productivity: {
        title: 'Productivity'
      },
    },
    things_to_do: {
      exercise: "Exercise regularly",
      eat_right: "Eat right",
      stress: "Tackle stress",
      smoke: "Stop smoking",
      hydrate: "Stay hydrated ", 
      diet: "Maintain a healthy diet",
      alcohol: "Avoid alcohol",
      sleep: "Sleep well and consistently",
      breathing: "Practice intentional breathing and mindfulness",
      green: "Add some greenery to your home",
      active: "Get fit and stay active",
      eat_well: "Eat well",
      drink: "Drink lots of water",
      fresh_air: "Get fresh air",
      drink_water: "Drink water",
      iron_food: "Eat iron-rich foods",
      train_breathing: "Train your slow and deep breathing",
      thankful: "Think of 3 things to be thankful for ",
      talk: "Talk to somone when you have too much on your plate",
      drinking: "Drinking a lot of clear liquids such as water, broth, and juices or a rehydration drink",
      bath: "Taking a lukewarm bath",
      rest: "Resting",
      cool: "Keeping yourself cool with lightweight clothing and bed coverings",
      sweat: "Sweat out by doing physical activities 30 minutes a day ",
      eat_healthy: "Eating a healthy diet ",
      meditate: "Manage stress by windling down with meditation ",
      strive_active: "Strive to be more active every day",
      veggies: "Add more veggies to your meal",
      fruits: "Swap fruits instead of sugary baked goods for desert",
      control_bp: "Keep your blood pressure and diabetes under control.",
      vaccine: "Get vaccinated.",
      distance: "Maintain a 1m distance between you and others.",
      mask: "Wear a fitted mask properly.",
      open_area: "Choose open, well-ventilated areas over closed ones.",
      wash_hands: "Wash your hands regularly with soap and water.",
      cover_cough: "When you cough or sneeze, cover your mouth and nose"
    },
    button: {
      mode: 'Mode',
      fever_percentage: 'Fever Percentage',
      fever_threshold: 'Threshold Temp.',
      start: 'START',
      stop: 'STOP',
      shutdown: 'SHUT DOWN',
      cancel: 'Cancel',
      ok: 'OK',
      all: 'ALL',
      power_on: 'POWER ON',
      full_screen: 'FULL SCREEN',
      save_settings: 'Save Settings',
      upgrade: 'Upgrade',
      change_password: 'Change Password',
      continue: 'continue',
      show_health_report: 'Show Health Report',
      clear_user_information: 'Clear User Info',
      send_to_email: 'Send to Email',
      rate_us: 'Rate Us!',
      submit: 'Submit',
    },
    labels: {
      sending: 'sending...',
      user_profile: 'User Profile',
      rate_us: 'Your feedback is valuable to us, please give us a rating!',
      poor: 'Poor',
      good: 'Good',
      dissatisfied: 'Dissatisfied', 
      satisfied: 'Satisfied', 
    },
    graph: {
      measurement: 'Measurement',
      time: 'Time',
      day: 'Day',
      week: 'Week',
      month: 'Month',
      trend_line: 'Show Trend Line',
      excellent: 'Excellent',
      good: 'Good',
      poor: 'Poor'
    },
    mode: {
      normal: 'Normal',
      debug: 'Debug'
    },
    threshold: {
      auto: 'Auto'
    },
    message: {
      startup: 'System is OFF, press "POWER ON" to start.',
      loading: 'Loading',
      calibrating: 'Stay still, Calibrating ...',
      no_face_box: 'No Face Box ...',
      stopping: 'Stopping {system_short_name}...',
      confirm_shutdown: 'Are you sure you want to shutdown the system?',
      important_message: 'Important Message',
      emmisivity: '{short_name} v{version} is designed to work with FLIR A315 with emmisivity set to <span class="text-danger font-weight-bold">{emmisivity}</span>.',
      auto_sys_restart: 'Automatic System Restart',
      prevent_overheat: 'Restarting the system to prevent over-heating...',
      camera_error: 'Camera Error Detected',
      system_error: 'System Error Detected',
      camera_fatal_error: 'System automatically stopped due to camera error, please contact {contact}. Thank you.',
      system_fatal_error: 'System automatically stopped due to error, please contact {contact}. Thank you.',
      system_off: 'System is OFF.',
      tap_to_view: 'Tap to View',
      condition_check_failed: '(1) Please move into the pur ple human <br/>(2) stay still',
      coming_soon: 'Coming Soon',
      breath_in: 'In',
      breath_out: 'Out',
      bad_scan_message: 'Bad scan, please restart the scan',
      weak_signal_message: 'Weak signal',
      settings_updated: 'Settings updated',
      password_updated: 'Password updated',
      password_update_failed: 'Failed to change password, please check your password',
      fill_profile: 'Please fill in your company profile',
      conditions: {
        lighting: "Lighting",
        distance: "Distance",
        centered: "Centered",
        movement: "Movement",
        server_ready: "Server Ready"
      },
      subscribe: "Subscribe to see this content!",
      scan_quality: "Scan Quality",
      email: 'Email sent to',
      feedback: "Feedback received, Thank You!! Have a nice day~",
    },
    disclaimer: {
      disclaimer1: "Disclaimer: The software does not store any personal data.",
    },
    copyright: "\u00A9 2021-2023 PanopticAI Ltd. All Rights Reserved.",
    last_scan: 'Last Scan',
    last_update: 'Last Updated',
    vs_descriptions: {
      bloodPressure: "The normal blood pressure for an adult is below 120/80 mmHg.",
      bmi: "The normal BMI for an adult is 18.5-24.9.",
      respiratoryRate: "The normal breathing rate for an adult is 6-24 bpm.",
      generalRisk: "Risk of getting cardiovascular diseases, the smaller the better.",
      covidRisk: "Risk of getting covid-19, the smaller the better.",
      heartRate: "The normal resting heart rate for an adult is between 60-100 bpm.",
      hrvSdnn: "The normal HRV (SDNN) for an adult is above ~20ms",
//      hrvSdnn: "The normal HRV (SDNN) for an adult is 68-219 ms throughout the whole day",
      hrvIbi: "The normal resting IBI for an adult is between 600-1000 ms.",
      spo2: "The normal O2 saturation for an adult is 95-100 %.",
      stress: "The normal resting stress rating is from 0-2.",
      temperature: "The normal body temperature for an adult is around 98.6°F (37°C).",
      facialSkinAge: "You are always young & charming ;)",
    },
    option: {
      yes: 'Yes',
      no: 'No',
      logout_yes: 'Yes',
      logout_no: 'No',
    },
    user: {
      supervisor: 'Supervisor',
      guest: 'Guest',
    },
    company_info: {
      company_information: "Company Information", 
      company_id: "Company Id", 
      project_id: "Project Id", 
      site_id: "Site Id", 
      location: "Site Location", 
      subscription_plan: 'Subscription Plan',
      valid_until: 'Valid Until',
    },
    user_info: {
      user_id: 'User Id',
      profile_image: 'Profile Image',
      personal_information: 'Personal Information',
      subscription_plan: 'Subscription Plan',
      valid_until: 'Valid Until',
      name: 'Name',
      nickname: 'Nickname',
      birthday: 'Birthday',
      height: 'Height (cm)',
      weight: 'Weight (kg)',
      gender: 'Gender at birth',
      male: 'Male',
      female: 'Female',
      unit_system: 'Unit System', 
      country: 'Countries',
      countryOptions: {
        hongkong: "Hong Kong, China", 
        china: "Mainland China",
      },
      metric: 'Metric',
      imperial: 'Imperial',
      medical_history: 'Medical History',
      smoking: 'Do you smoke?',
      hypertension: 'Do you have hypertension?',
      bp_medication: 'Are you taking blood pressure medication?',
      diabetic: 'Are you diabetic?',
      heart_disease: 'Do you have heart disease?',
      depression: 'Do you have depression?',
      department: 'Department',
      type_1: 'Type 1',
      type_2: 'Type 2',
      plan_type: {
        free: 'Free',
        pro: "Pro",
        enterprise: 'Enterprise',
        team: 'Team',
        unlimited: 'Unlimited'
      }
    },
    password: {
      password: 'Password',
      old: 'Old Password',
      new: 'New Password',
      confirm: 'Confirm Password',
      not_match: 'Password does not match'
    },
    detailed_info: {
      history: 'History',
      trend_analysis: 'Trend Analysis',
      things_to_do: 'Things to do',
      note: 'Note',
      references: 'References',
    },
    no_data: 'No Data',
    analysis: {
      record: 'From the records on the past 7 days, your {tag} is in {quality} condition with the average of {average} {unit}',
      changing: '{conjunction} it is {slope} to a {condition} condition.',
      decrease_worse: '{conjunction} it is showing a slight downward trend. Maintain it to keep it from decreasing to a worse condition.',
      increase_worse: "{conjunction} it is showing a slight upward trend. Maintain it to keep it from highly increasing to a worse condition.",
      constant: 'From the records on the past 7 days, your {tag} is constantly in {quality} condition with the average of {average} {unit}.',
      good_work: 'Keep up the good work!',
      improve: "See 'Things To Do' for suggestions to maintain your health!",
      better: 'better',
      worse: 'worse',
      however: '. However,',
      and: ', and',
      decreasing: 'decreasing',
      increasing: 'increasing',
      decrease: " Lower it to make it better!",
      increase: " Increase it to make it better!",
      anomalies: "On {date} there's unusual behavior recorded on your {vital_sign}.",
      excellent: "excellent",
      good: "good",
      poor: "poor",
      low: "low",
      moderate: "moderate",
      high: "high"
    },
    onboarding: {
      homepage: {
        step1: {
          title: "Power On Button",
          description: "Click this button to power up the system",
        },
        step2: {
          title: "Start Button",
          description: "When the system is powered on, you can start scanning by clicking this button",
        },
        step3: {
          title: "Navigation Bar",
          description: `Use the navigation bar to navigate between pages.`,
        },
        step4: {
          title: "Homepage",
          description: "This button navigate you to homepage, which is the current page, where you can scan your vital signs",
        },
        step5: {
          title: "Dashboard",
          description: "This button navigate you to dashboard where you can see the overview of your scanning results",
        },
        step6: {
          title: "Supervisor View",
          description: "This button navigate you to the supervisor view page where you can see your team members' health data",
        },
        step7: {
          title: "Event Page",
          description: "This page will be coming soon!",
        },
        step8: {
          title: "Email",
          description: "This page will be coming soon!",
        },
        step9: {
          title: "Chat",
          description: "This page will be coming soon!",
        },
        step10: {
          title: "Settings Page",
          description: "This button navigate you to settings page where you can edit your personal information and set your preferences for using the system",
        },
        step11: {
          title: "Help Page",
          description: "This button will navigate you to help page for more detailed information on how to use the system",
        },
        step12: {
          title: "Logout",
          description: "This button will navigate you to logout",
        },
        step13: {
          title: "Language",
          description: "You can change language here",
        },
      },
      dashboard: {
        step1: {
          title: "General Wellness Chart",
          description: "This chart shows the overall score of your generall wellness throughout a period of time",
        },
        step2: {
          title: "Time Period",
          description: "You can change the time period to be shown in the chart here"
        },
        step3: {
          title: "Trend Line",
          description:" You can choose whether to display the trend line on the chart by clicking here",
        },
        step4: {
          title: "Vital Signs",
          description: "The complete vital signs' latest measurements can be seen here"
        },
        step5: {
          title: "Detailed Information",
          description: "You can view the detailed information of each vital sign by clicking on this button"
        },
        step6: {
          title: "Share",
          description: "Share your vital sign measurement by clicking this button"
        },
        step7: {
          title: "General Fact",
          description: "Some general fact of each vital sign can be seen by cliking this button"
        }
      }
    },
    help_content: {
      disclaimer: {
        title: "Disclaimer",
        p1: "The camera-based health and wellness monitoring solution is designed for contactless measurement \
        of health vital signs such as heart rate, breathing rate, blood pressure, and O2 saturation. Video \
        footage is captured through a device's camera, but no video footage is stored.",
        p2: "WVS is to be used by individuals who do not require constant or intensive health monitoring. The device \
        is not to be used as the only method for health vital signs measurement of the individuals and is not intended \
        to replace consultations with medical experts. The measurements are not served for medical purposes. Should you \
        have any further health enquiries, please contact health care professionals or other medical experts immediately."
      },
      setup: {
        title: "Set-up Guidelines",
        dos: {
          title: "Dos",
          p1: "For the best results, please do the following for the scanning:",
          1: "Put the camera near your eye level",
          2: "Prevent shaking the device ",
          3: "Choose a well-lit area, ensuring light (preferably indoor light) is evenly shed on your face",
          4: "Avoid backlighting ",
          5: "Keep your face well within the box and facing the camera",
          6: "Ensure suitable distance between you and the camera"
        },
        donts: {
          title: "Dont's",
          1: "Do not wear a mask",
          2: "Do not wear a hat",
          3: "Do not wear anything that will cover your face",
          4: "Do not have uneven light on you face (e.g. sunlight)",
          5: "Do not talk",
          6: "Do not make big movements",
          7: "Do not turn your head aside",
        },
        low_accuracy: {
          title: "Low Accuracy Examples",
          1: "The area is too bright or too dark",
          2: "Uneven lighting or backlighting",
          3: "The user is wearing a hat or having something cover one’s face",
          4: "The user is not facing the camera",
          5: "The distance between the user and the camera is too far",
          6: "The distance between the user and the camera is too close"
        }
      },
      operation: {
        title: "Operation",
        ground_truth: {
          title: "Measure Ground Truth with Contact-Based Device",
          p1: "The following steps should be taken before activating the WVS system so the user can compare the results from the WVS system with the results from the contact-based pulse oximeter.",
          p2: "The contact-based pulse oximeter is off until a finger is placed in it. To turn on and operate device, follow the following steps:",
          caption: `Contact-Based Device Scanning`,
          1: "Squeeze the back of the device to open it.",
          2: "Insert the index finger or ring finger into the device with the display facing up. For a left-handed person, use the left-hand index finger or ring finger. For a right-handed person, use the right-hand index finger or ring finger.",
          3: "The tip of the finger should reach the finger stop inside of the device. If the finger is not correctly positioned, repeat from Step 1.",
          4: "The device will now show your vital signs on the display.",
          p3: `For more detailed instructions on how to use the contact-based device, please refer to the manual for the device <a href="https://techdocs.masimo.com/globalassets/techdocs/pdf/lab-10169a_master.pdf">here</a>.<br>`
        },
        scanning: {
          title: "Using The Health Scanning System",
          p1: "The following steps are done to use the WVS scanning system:",
          1: "Sit comfortably in a well-lit area before scanning. Ensure the light (preferably indoor light) is evenly shed on your face. Then place the computer on a sturdy surface to prevent shaking.",
          2: `Click on the home button (the first icon) on the Navigation Bar on the left side of the WVS system. Then click on the green '<i>Power On</i>' 
          on the top right corner to start up the camera and face scanner system. The page will then display '<i>Starting up …</i>' as shown in Figure 2 below. Click allow, if prompted to enable WVS to use your camera.`,
          caption_2: "Starting up the vital sign scanner",
          3: "When the system is ready, the system will activate your device’s camera and show it in the <i>Homepage</i> along with a green box indicating your detected face. Keep your face in the center of the green frame and facing the lens while ensuring that there is an appropriate distance between your body and the camera.",
          caption_3: "Powered up system",
          4: `Click on the green circle "<i>Start</i>" button to begin the scanning process. Before beginning the scan, you will need to follow pass the <i>Scanning 
          conditions</i> shown in the face scanner and stay still while the scanner is calibrating. Once the scanning conditions are met, a three second 
          countdown will appear in the middle of the screen to show that the scan is starting.`,
          caption_4: "Conditions for scanning",
          5: `Follow the <i>Breathing guide</i> instructions that appears in the green circle throughout the scanning process. The breathing guide will change 
          between '<i>In</i>' and '<i>Out</i>' to indicate breathing in and breathing out, as shown in Figure 12 and Figure 13. The <i>Scanning progress</i> circle 
          can be seen in the scanner too. The <i>Scanning progress</i> circle indicates the remaining time till the scanning procedure is completed. `,
          caption_5: "Scanning started",
          note_5: `Note: Users are reminded to follow the in and out guide to breath at normal resting speed, although no error in the scan will be detected if the 
          breathing in and out are not matched precisely with the visual indicator.`,
          6: `The scanning progress will complete within 30 seconds, and the vital sign results will be displayed while scanning is 
          being completed. Please wait until the scan is complete to see the results. `,
          caption_6: "Basic vital signs",
          7: `To see the detailed scanning result, go to the "<i>Dashboard</i>" by clicking on the <i>Navigation bar</i>.`,
          caption_7: "Scanning results overview",
          laptop: "Using Laptop",
          phone: "Using Phone",
          pc: "Usign PC"
        }
      }
      
    },
    scan_tutorial: {
      title: "Quick Guide for Scanning on Vitals",
      dos: "Dos:",
      donts: "Don'ts:",
      step1: {
        title: "Position Yourself Inside The Green Border",
        paragraph: "Once you click on the start button, a green human border will appear on your screen. This will guide you for a correct position and distance to make the scanning more effective. Make sure you are positioning yourself to be inside the green border.",
        do1: "Put the camera near your eye level",
        do2: "Keep your face well within the border and facing the camera",
        do3: "Ensure suitable distance between you and the camera",
        dont1: "Do not sit too far away from or too close to the device",
      },
      step2: {
        title: "Ensure Camera is Steady",
        paragraph: "Shaky camera may affect the reading of the scanning. Please make sure that the device is placed on a steady place and do minimum movement.",
        do1: "Place device on steady surface",
        do2: "Prevent shaking the device",
        dont1: "Do not talk",
        dont2: "Do not make big movements "
      },
      step3: {
        title: "Whole Face Displayed on Camera",
        paragraph: "Make sure that your face is fully displayed on camera. Remove anything covering your face.",
        do1: "Face the camera",
        do2: "Make sure your face is not covered such as with hat, hoodie, mask, hair.",
        dont1: "Do not wear anything that cover your face (hat, hoodie, mask)",
        dont2: "Do not turn your face sideways"
      },
      step4: {
        title: "Ensure Even and Sufficient Lighthing on Your Face",
        paragraph: "Uneven lighting may affect the correctness of your reading. Indoor lighting is recommended to ensure correct lighting more easily",
        do1: "Choose a well-lit area, ensuring light (preferably indoor light) is evenly shed on your face",
        dont1: "Do not have uneven light on you face (e.g. sunlight)",
        dont2: "Backlighting" 
      }
    },
    health_report: {
      title: "PERSONAL HEALTH REPORT",
      poor: "Poor",
      excellent: "Excellent",
      age: "Age",
      sex: "Sex",
      female: "F",
      male: "M",
      email: "Email",
      phone_number: "Phone Number",
      height: "Height",
      weight: "Weight",
      bmi: "BMI",
      stress_level: "Stress Level",
      general_wellness: "General Wellness",
      wellness: {
        title: "Wellness",
        general_wellness: {
          title: "General Wellness",
          definition: "General wellness score is calculated through a weighted score of the core vital signs and mental stress level.",
          range: "The normal general wellness score ranges from 80-100.",
          result: "Your general wellness score is {value}."
        },
        stress: {
          title: "Stress Level",
          definition: "Stress level is a feeling of emotional or physical tension. High level of stress  may be caused by any event or thought that makes you feel frustrated, angry, or nervous.",
          range: "The normal resting stress rating is from 0-2.",
          result: "Your stress level is {value}."
        }
      },
      respiratory: {
        title: "Respiratory",
        respiratory_rate: {
          title: "Respiratory Rate",
          definition: "Respiratory rate is the number of breaths a person takes per minute. High level of respiratory rates may be caused by exercise, asthma, anxiety, etc. Low level of respiratory rate may be caused by side effects from medications or an underlying medical problem.",
          range: "Normal respiration rates for an adult person at rest range from 12 to 25 breaths per minute.",
          result: "Your respiratory rate is {value} bpm."
        },
        spo2: {
          title: "Oxygen Saturation",
          definition: "Oxygen saturation is a measure of how much oxygen the red blood cells are carrying from the lungs to the rest of the body. Low level of oxygen saturation (hypoxemia) may be caused by an underlying illness that affects blood flow or breathing.",
          range: "Most people will read an SpO<sub>2</sub> of 95% or above.",
          result: "Your SpO<sub>2</sub> is {value}%."
        }
      },
      cardiovascular: {
        title: "Cardiovascular",
        heart_rate: {
          title: "Heart Rate",
          definition: "Heart rate is the number of beats of the heart per minute (bpm). High level of heart rate  may be caused by anxiety, heavy caffeine/alcohol consumption, etc.",
          range: "The normal resting heart rates ranges from 60-100 bpm",
          result: "Your heart rate is {value} bpm.",
        },
        hrv_sdnn: {
          title: "Heart Rate Variability SDNN",
          definition: "HRV is a measure of the variation in time between each heartbeat. SDNN is the standard deviation of the interval between normal heartbeats. Low level of heart rate variability may be caused by stress of lack of sleep.",
          range: "The heart rate variability SDNN above 100 ms are healthy.",
          result: "Your heart rate variability SDNN is {value} ms."
        },
        hrv_ibi: {
          title: "Heart Rate Variability IBI",
          definition: "HRV is a measure of the variation in time between each heartbeat. IBI is the interbeat interval. In normal heart function, each IBI value varies from beat to beat.",
          range: "Typical heart rate variability IBI values range from 500-1300 ms.",
          result: "Your heart rate variability ibi is {value} ms.",
        },
        systolic_bp: {
          title: "Systolic Blood Pressure",
          definition: "Systolic blood pressure is the peak pressure in your brachial arteries that results from the contraction of your heart muscle. Low level of blood pressure may be caused by dehydration, pregnancy, shock, endocrine disorders, etc.",
          range: "The ideal systolic blood pressure is between 90-120mmHg.",
          result: "Your systolic blood presusre is {value} mmHg.",
        },
        diastolic_bp: {
          title: "Diastolic Blood Pressure",
          definition: "Diastolic blood pressure is the amount of pressure in your brachial arteries when your heart muscle is relaxed. High blood pressure may be caused by unhealthy lifestyle or certain health conditions, such as diabetes and having obesity.",
          range: "The ideal diastolic blood pressure is between 60-80 mmHg.",
          result: "Your diastolic blood pressure is {value} mmHg."
        }
      },
      disclaimer: {
        title: "Disclaimer",
        p1: "The camera-based health and wellness monitoring solution is designed for contactless measurement of health vital signs such as heart rate, breathing rate, blood pressure, and O<sub>2</sub> saturation. Video footage is captured through a device's camera, but no video footage is stored.",
        p2: "The device is not to be used as the only method for health vital signs measurement of the individuals and is not intended to replace conulstations with medical experts. The measurements are not served for medical purposes. Should you have any further health enquiries, please contact health care professionals or other medical experts immediately.",
        powered_by: 'Powered by ',
        powered_by_2: ""
      }
    }
  },
  '中文': {
    page: {
      dashboard: '面板',
      settings: '設置',
      help: '幫助',
      logout: '登出',
      supervisor_view: '管理員視圖',
      coming_soon: '敬請關注!<br>我們即將到來...',
    },
    guest_signin: '繼續以訪客身份登錄',
    logout_prompt: '你確定你要退出嗎？',
    instruction: {
      stay_still: '正在測量中，請保持靜止。',
      count_down1: '將在',
      count_down2: '秒後開始測量，請保持靜止。',
    },
    vital_signs: {
      heartRate: {
        title: '心率',
        body: [
          "您的心率為每分鐘{value}次，處於{condition}的心率範圍內。 對於大多數健康的成年女性和男性來說，靜息心率範圍為每分鐘 60 至 100 次。",
          "關於心率有趣的事實是，您的心臟每分鐘泵出約 1.5 加侖 (約5.7升) 的血液。 在一天的過程中，加起來超過 2,000 加侖 (約7571升)。 女性的平均心跳每分鐘比男性快 8 次。",
          "當您在「目標心率區」（即心率（脈搏）為最大值的 60% 至 80% 時）鍛煉時，您會獲得最大的益處。"
        ],
        excellent: "繼續堅持你的健康生活方式！",
        good: "做得好！記得要做適量運動並管理壓力以保持這種良好狀態。",
        poor: "注意你不是做完運動後掃描！如果不是，請去看醫生，提早預防潛在的疾病",
      },
      hrvIbi: {
        title: '心率變異性 IBI',
        body: [
          "您的 IBI 處於{condition}的狀態，大約是 {value} ms。儘管數值因人而異，這取決於鍛煉、生活方式和生物因素。",
          "關於 IBI 的一個有趣的事實是，它實際上是由交感神經系統和副交感神經系統控制的，交感神經系統指示心臟跳得快些，副交感神經系統則指示心臟跳得慢些。",
          "心跳的時間間隔(IBI) 是心電圖 (ECG) 上，心跳與心跳間時間域的變化。 最佳的、通常較高水平的心率變異度代表身體能應對不斷變化的環境並自我調節，從而改善整體健康狀況。"
        ],
        excellent: "你做的很棒！",
        good: "記得經常做運動。",
        poor: "記得定期做運動，如果出現任何病症，請去看醫生。",
      },
      hrvSdnn: {
        title: '心率變異性SDNN',
        body: [
          "您的 SDNN 處於{condition}的狀態，即 {value} ms。儘管它因人而異，但這亦取決於鍛煉、生活方式和生理因素。",
          "有關心率變異度數據能反映很多身體狀況，例如是身體自律神經的平衡狀況、血壓、氣體交換、腸道健康、心臟血管張力調節（這是指調節血壓的血管的直徑），可能還有面部肌肉。",
          "IBI 的標準差(SDNN) 是最廣泛使用的 HRV 測量方法。 當SDNN的記錄時長多於24小時，它將會是一個很好的心臟風險指標。 SDNN 能預測發病率和死亡率。 例如，SDNN 值低於 50 ms 的患者的死亡風險，是SDNN 值高於 100 ms 的患者的 5.3 倍。"
        ],
        excellent: "做得好！",
        good: "做得好！記得保持良好的睡眠習慣。",
        poor: "記得早睡早起和和定期鍛煉。",
      },
      respiratoryRate: {
        title:'呼吸頻率',
        body: [
          "您每分鐘呼吸的次數是 {value}，即 {condition}。成年人休息時的正常呼吸頻率為每分鐘 12 至 16 次。",
          "請不要在運動後立即檢查生命體徵。 關於呼吸系統的一個有趣的事實是，您會因呼吸而流失大量水分。 人類每小時呼出的水量高達 17.5 毫升。",
          "呼吸頻率是一種基本生命體徵，能夠反映不同的病理狀況（例如異常心臟活動、肺炎和臨床病情惡化），以及壓力源（包括情緒壓力、認知負荷、熱、冷、體力消耗和運動引起的疲勞）。"
        ],
        excellent: "你做得很好！",
        good: "做得好!記得避免接觸污染物，讓情況變得更好。",
        poor: "",
      },
      spo2: {
        title: '血氧飽和度',
        body: [
          "您血液中的氧氣百分比為 {value}%，屬於{condition}的表現。 正常的氧氣水平通常為 95% 或更高。",
          "SpO2，也稱為血氧飽和度，是血液中攜帶氧血紅蛋白量相對於非帶氧血紅蛋白量的比例。 血液中需含有一定水平的氧氣，否則身體將無法有效地運作。",
          "氧氣在體內受到嚴格調節，因為低氧血症會對大腦、心臟和腎臟等器官產生許多急性不良影響。"
        ],
        excellent: "你真的做得很好！記得保持你的健康生活方式還有定期運動啊！",
        good: "記得要經常運動來保持SpO2於正常範圍内。",
        poor: "建議你增加健康食物到你的餐單。例如：黑莓、藍莓、芸豆，以改善你的健康。",
      },
      stress: {
        title: '壓力程度',
        body: [
          "您的壓力等級是 {value}，屬於{condition}。 正常壓力水平應在 0-2 左右",
          "你知道壓力水平是由神經系統控制的嗎？當我們面臨危險時，我們的中央神經系統會處於「面對或逃跑」 的模式。這個時候，我們的心率會上升，血管收縮，然後我們的心理會變得更敏感。身體之所以出現這些變化，是要讓我們為下一步的迅速採取行動做好準備，去面對威脅，或是逃跑。",
          "壓力不僅影響您的情緒，還與您的身體健康密切相關。 壓力會引致生理反應，例如肌肉酸痛和緊繃，這是一種保護身體免受傷害的自然防禦。"
        ],
        excellent: "你並無壓力過大的情況！ 感謝您照顧好自己的心理和精神健康！",
        good: "記得偶爾休息一下，保持這種良好的狀態。",
        poor: "當您壓力過大時，請記住找人傾訴。",
      },
      temperature: {
        title: '溫度 (Beta)',
        body: [
          "您的體溫現在是 {value}°C，屬於{condition}的範圍。",
          "一個關於體溫的有趣事實:有時涼爽的皮膚可以保護你的大腦。醫生們更發現，在心臟驟停後立即降低體溫可以減少對大腦的損害，並提高患者康復的機會。",
          "如果您的體溫高於 39.4 ℃，請盡快就醫"
        ],
        excellent: "",
        good: "您做得很好！ 記得多補充水分。",
        poor: "請尋求醫生的專業意見!",
      },
      bloodPressure: {
        title: '血壓 (Beta)',
        body: [
          "您的血壓現在是 {value} mm Hg，是{condition}的。 正常血壓水平應低於 120/80 mmHg。",
          "通常，血壓會在您醒來前的幾個小時開始上升。 它會在白天繼續上升，在中午達到頂峰。 血壓通常在下午晚些時候和晚上下降。 晚上睡覺時血壓則通常較低。",
          "正常的血壓應少於120/80 mmHg。 通常血壓會在您醒來前的幾個小時開始上升。 它會在白天繼續上升，在中午達到頂峰。 血壓通常在下午晚些時候和晚上下降。 晚上睡覺時血壓則通常較低。"
        ],
        excellent: "請不要有太大的情緒起伏。",
        good: "謹記，如果您感到頭暈目眩,請嘗試閉上眼睛並專注在您的呼吸上。",
        poor: "請用5分鐘進行冥想練習，暫時忘記煩擾。",
      },
      bmi: {
        title: '體重指數BMI',
        body: [
          "根據您的身高和體重，您的 BMI 為 {value}。 對於 20 歲或以上的成年人，健康的 BMI 範圍為 18.5 - 24.9。",
          "您知道如何計算您的BMI嗎？ BMI = 體重 (kg) / 身高 (m)^2",
          "如果您的 BMI 數值表明您超重或肥胖，您可能面臨患上某些疾病的風險。 這些包括糖尿病、心臟病、關節炎和某些癌症。 但是，BMI不能用於診斷疾病。"
        ],
        excellent: "你做得非常好！請繼續保持均衡飲食及恆常運動。",
        good: "你做得很好！請繼續保持均衡飲食及恆常運動，讓BMI 水平繼續處於18.5-24.9之間。",
        poor: "",
      },
      generalRisk: {
        title: '心血管疾病風險 (Beta)',
        body: [
          "您現時的心血管疾病風險為 {value}%，屬於 {condition} 心血管疾病患病風險。",
          "你知道心碎是可能發生的嗎？ 它被稱為心碎綜合徵，類似於心髒病發作時的症狀。 心髒病發是由心髒病引起，心碎綜合症則是由情緒或身體壓力事件引起的壓力荷爾蒙增加而引起。 [1]",
          "如果您的心血管疾病風險高於 15%，表示您患心血管疾病的風險很高。 心血管疾病 (CVDs) 泛指心臟和血管的疾病，包括冠心病、腦血管疾病、外周動脈疾病、風濕性心髒病等。 [3]"
        ],
        excellent: "",
        good: "",
        poor: "",
      },
      covidRisk: {
        title: 'Covid感染風險 (Beta)',
        body: [
          "您現時的新冠病毒風險為 {value}%，屬於 {condition} 的新冠病毒患病風險。",
          "您知道新冠病毒風險水平受許多不同相互作用的因素影響嗎？它們包括年齡和現有的健康狀況，例如心髒病、肺病、糖尿病、高血壓和超重。 [2]\
          <br><br>\
          新冠病毒感染者最常見的症狀包括發燒、乾咳和疲勞。 有些感染者可能只有非常輕微或非特異性症狀，而有些感染者可能會出現嚴重的症狀，如呼吸急促、胸痛或意識模糊。 [3]\
          ",
          "如果您的新冠病毒風險高於 20% 並感到病症嚴重，請立即就醫。 如果您感到病症輕微，請留在家中並自我隔離直至症狀好轉。"
        ],
        excellent: "",
        good: "",
        poor: "",
      },
      facialSkinAge: {
        title: '面部皮膚年齡',
        body: [
          "您的面部皮膚年齡是{value}。您很年輕美麗/帥氣！",
          "非常有趣的是成人的肌膚老化會在25嵗逐漸明顯。",
          "衰老最明顯的跡象之一是皮膚變化。 明顯的老化跡象會隨年齡增長迅速出現在您的皮膚上。 雖然健康的生活方式和有效的護膚方案可以控制一些與皮膚相關的因素，但許多皮膚衰老情況仍與年齡，以及遺傳因素有關。7種常見的皮膚衰老跡象：\
          <ul>\
            <li>\
            細紋和皺紋\
            </li>\
            <li>\
            皮膚暗沉\
            </li>\
            <li>\
            膚色不均\
            </li>\
            <li>\
            皮膚乾燥\
            </li>\
            <li>\
            斑點和老年斑 \
            </li>\
            <li>\
            皮膚紋理粗糙\
            </li>\
            <li>\
            可見的毛孔\
            </li>\
          </ul>"
        ],
        excellent: "",
        good: "",
        poor: "",
      },
      bloodSugar: {
        title: '血糖',
        body: [
          "To be completed.",
          "To be completed.",
          "To be completed."
        ],
        excellent: "",
        good: "",
        poor: "",
      },
      bloodAlcohol: {
        title: '血醇',
        body: [
          "To be completed.",
          "To be completed.",
          "To be completed."
        ],
        excellent: "",
        good: "",
        poor: "",
      },
      general_wellness: {
        title: '整體健康狀況',
      },
      energy: {
        title: '營養',
      },
      productivity: {
        title: '效率'      
      },
    },    
    things_to_do: {
      exercise: "經常運動",
      eat_right: "健康飲食",
      stress: "降低壓力",
      smoke: "停止吸煙",
      hydrate: "多補充水分", 
      diet: "維持健康餐單",
      alcohol: "避免飲酒",
      sleep: "保持有規律且良好的睡眠",
      breathing: "進行呼吸法練習和靜觀練習",
      green: "為您的家添加一些綠色植物",
      active: "保持健康並保持活躍",
      eat_well: "食得健康",
      drink: "飲充足的水",
      fresh_air: "呼吸新鮮空氣",
      drink_water: "飲水",
      iron_food: "進食富含鐵質的食物",
      train_breathing: "練習慢且深的呼吸",
      thankful: "想三件值得感恩的事",
      talk: "當您感到太大壓力時，請找人傾訴。",
      drinking: "喝大量清澈的液體，如清水、湯水和果汁或水份補充飲料",
      bath: "洗個暖水澡",
      rest: "休息",
      cool: "用輕便的衣服和床罩讓自己保持涼爽",
      sweat: "每天進行30分鐘的鍛煉，出一身汗",
      eat_healthy: "養成健康的飲食習慣",
      meditate: "透過冥想放鬆來管理壓力",
      strive_active: "積極面對每一天",
      veggies: "在您的膳食中加入更多的蔬菜",
      fruits: "用水果代替含糖的烘焙食品作爲飯後甜品",
      control_bp: "控制血壓和糖尿病",
      vaccine: "接種疫苗",
      distance: "與他人保持一米社交距離",
      mask: "正確地佩戴合適的口罩",
      open_area: "選擇開放、通風良好的地方而不是密不透風的地方",
      wash_hands: "經常使用肥皂和清水洗手",
      cover_cough: "咳嗽或打噴嚏時，摀住口鼻`"
    },
    button: {
      mode: '顯示模式',
      fever_percentage: '臉上發燒皮膚比例',
      fever_threshold: '發燒檢查體溫',
      start: '開始',
      stop: '停止',
      shutdown: '關機',
      cancel: '取消',
      ok: '確定',
      all: '所有',
      power_on: '開機',
      full_screen: '全屏',
      save_settings: '保存設置',
      upgrade: '升級',
      change_password: '更改密碼',
      next: "下一步",
      previous: "上一步",
      finish: "完成",
      continue: '繼續',
      show_health_report: '顯示健康報告',
      clear_user_information: '清除用戶資料',
      send_to_email: '發送到郵件',
      rate_us: ' 評價我們！',
      submit: '提交',
    },
    labels: {
      sending: '正在發送...',
      user_profile: '用戶資料',
      rate_us: ' 您的反饋意見對我們很重要，請給我們評個分！',
      poor: '劣',
      good: '優',
      dissatisfied: '不滿意', 
      satisfied: '滿意', 
    },
    graph: {
      measurement: '測量',
      time: '時間',
      day: '日',
      week: '周',
      month: '月',
      trend_line: '顯示趨勢線',
      excellent: "優秀",
      good: "良好",
      poor: "欠佳",
    },
    mode: {
      normal: '正常',
      debug: '調校'
    },
    threshold: {
      auto: '自動'
    },
    message: {
      startup: '系統已關閉。請按「開始」。',
      loading: '加載中',
      calibrating: '保持靜止，智能系統調整中 ...',
      no_face_box: '找不到人臉 ...',
      stopping: '{system_short_name} 智能系統停止中...',
      confirm_shutdown: '確定要關閉主機嗎?',
      important_message: '重要訊息',
      emmisivity: '{short_name} v{version} 是專為 Emmisivity <span class="text-danger font-weight-bold">{emmisivity}</span> 的 FLIR A315 而設計的。',
      auto_sys_restart: '自動重新啟動',
      prevent_overheat: '避免系統過熱，重新啟動中...',
      camera_error: '發現相機錯誤',
      system_error: '發現系統錯誤',
      camera_fatal_error: '因相機問題，系統自動停止，請聯絡 {contact}。謝謝。',
      system_fatal_error: '因系統發生問題，系統自動停止，請聯絡 {contact}。謝謝。',
      system_off: '系統已關閉。',
      tap_to_view: '按一下觀看。',
      condition_check_failed: '(1) 請移動進人形框內 <br/>(2) 盡量保持靜止。',
      coming_soon: '即將推出',
      breath_in: '吸',
      breath_out: '呼',
      bad_scan_message: '請重新掃描',
      weak_signal_message: '信號微弱',
      settings_updated: '設定已更新',
      password_updated: '密碼已更新',
      password_update_failed: '更改密碼失敗，請檢查您的密碼是否正確',
      fill_profile: '請填寫您的用戶資料',
      conditions: {
        lighting: "均匀光線",
        distance: "適當距離",
        centered: "面部置中",
        movement: "保持靜止",
        server_ready: "伺服器就緒"
      },
      subscribe: "訂閱以觀看內容!",
      scan_quality: "掃描質量",
      email: '電郵發送至',
      feedback: "已收到您的意見，非常感謝！祝您有個美好的一天~",
    },
    disclaimer: {
      disclaimer1: "免責聲明：此軟件不儲存任何個人資料。",
    },
    copyright: "\u00A9 2021-2023 全境智能有限公司 版權所有",
    last_scan: '最後一次掃描',
    last_update: '最後更新',
    vs_descriptions: {
      bloodPressure: "成人的血壓小於120/80 mmHg。",
      bmi: "成人的身體質量指數在18.5與24.9之間。",
      respiratoryRate: "成人的呼吸率一般在每分鐘6至24次。",
      generalRisk: "得到心血管疾病的概率，越小越好。",
      covidRisk: "得到新冠肺炎的概率，越小越好。",
      heartRate: "成人的靜息心率在每分鐘60至100下。",
      hrvSdnn: "成人的心率變化大約是20ms或以上。",
//      hrvSdnn: "成人一天内的心率變化大約是68-219ms之間。",
      hrvIbi: "成人的靜息IBI在600至1000 ms之間。",
      spo2: "成人的血氧量大約爲95-100%。",
      stress: "正常的壓力指數應低於2。",
      temperature: "成人的正常體溫大約是37攝氏度。",
      facialSkinAge: " 您一直都年輕美麗/帥氣!",
    },
    option: {
      yes: '有',
      no: '沒有',
      logout_yes: '是',
      logout_no: '不是',
    },
    user: {
      supervisor: '管理員',
      guest: '訪客',
    },
    company_info: {
      company_information: "公司用戶信息", 
      company_id: "公司用戶編號", 
      project_id: "項目編號", 
      site_id: "站點編號", 
      location: "站點位置", 
      subscription_plan: '訂閲計劃',
      valid_until: '有效期至',
    },
    user_info: {
      user_id: '用戶編號',
      profile_image: '頭像',
      personal_information: '個人信息',
      subscription_plan: '訂閲計劃',
      valid_until: '有效期至',
      name: '名稱',
      nickname: '綽號',
      birthday: '生日',
      height: '身高（厘米）',
      weight: '體重（公斤）',
      gender: '出生性別',
      male: '男',
      female: '女',
      unit_system: '單位系統', 
      country: '國家',
      countryOptions: {
        hongkong: "香港，中國", 
        china: "中國大陸",
      },
      metric: '公制',
      imperial: '英制',
      medical_history: '病史',
      smoking: '您有吸煙嗎？',
      hypertension: '您有高血壓嗎？',
      bp_medication: '您有在服用降血壓藥物嗎？',
      diabetic: '您是糖尿病患者嗎？',
      heart_disease: '您有心臟疾病嗎？',
      depression: '您有抑鬱症嗎?',
      department: '部門',
      type_1: '類型1',
      type_2: '類型2',
      plan_type: {
        free: '免費',
        pro: "專業",
        enterprise: '企業',
        team: '團隊',
        unlimited: '無限制'
      }
    },
    password: {
      password: '密碼',
      old: '舊密碼',
      new: '新密碼',
      confirm: '確認密碼',
      not_match: '密碼不相符'
    },
    detailed_info: {
      history: '歷史數據',
      trend_analysis: '趨勢分析',
      things_to_do: '需做事項',
      note: '注釋',
      references: '參考資料',
      content: {
        anomalies: "在 {date}，您的 {vital_sign} 記錄有異常情況",
      },
    },
    no_data: '沒有數據',
    analysis: {
      record: "根據過去 7 天的記錄，您的{tag}處於{quality}的狀態，平均為 {average} {unit}",
      changing: '{conjunction}它正在{slope}到{condition}的狀態。',
      decrease_worse: '{conjunction}它顯示出輕微下降的趨勢。請保持現況，以防止它進一步減少。',
      increase_worse: "{conjunction}它顯示出輕微上升的趨勢。請保持現況，以防止它進一步增加。",
      constant: '根據過去 7 天的記錄，您的{tag}一直處於{quality}的狀態，平均為 {average} {unit}',
      good_work: '保持良好的現況！',
      improve: "請參閱「應做事項」以保持健康！",
      better: '更好',
      worse: '更差',
      however: '。但是，',
      and: '，而',
      decreasing: '下降',
      increasing: '上升',
      decrease: " 讓它減少且變好！",
      increase: " 讓它增加且變好！",
      anomalies: "您的{vital_sign}記錄在{date}出現異常。",
      excellent: "優秀",
      good: "良好",
      poor: "欠佳",
      low: "低",
      moderate: "中",
      high: "高"
    },
    onboarding: {
      homepage: {
        step1: {
          title: "開機按鈕",
          description: "點擊這個按鈕，已開啓系統",
        },
        step2: {
          title: "啓動按鈕",
          description: "當系統啓動後，你可以通過點擊這個按鈕開始掃描。",
        },
        step3: {
          title: "導航欄",
          description: `使用導航欄浏覽各頁。`,
        },
        step4: {
          title: "主頁",
          description: "這個按鈕將把你導航到主頁，也就是當前的頁面，你可以在那裏掃描你的人體體征",
        },
        step5: {
          title: "面板",
          description: "這個按鈕將引導你到面板，在那裏你可以查看你的掃描成績的概述。",
        },
        step6: {
          title: "管理員視圖",
          description: "這個按鈕將引導你到管理員視圖頁面，你可以看到你的團隊成員的健康數據。",
        },
        step7: {
          title: "活動頁面",
          description: "這個頁面很快就會推出!",
        },
        step8: {
          title: "電子郵件頁面",
          description: "這個頁面很快就會推出!",
        },
        step9: {
          title: "聊天箱",
          description: "這個頁面很快就會推出!",
        },
        step10: {
          title: "設置頁面",
          description: "這個按鈕將引導你進入設置頁面，在這裏你可以編輯你的個人信息並設置你的系統使用偏好。",
        },
        step11: {
          title: "幫助頁面",
          description: "這個按鈕將引導你進入幫助頁面，了解更多關于如何使用該系統的詳細信息",
        },
        step12: {
          title: "登出",
          description: "這個按鈕將引導你進入登出頁面6",
        },
        step13: {
          title: "語言",
          description: "你可以在這裏更換語言",
        },
      },
      dashboard: {
        step1: {
          title: "整體健康狀況圖",
          description: "這個圖表顯示了你在一段時期內的總體健康狀況。",
        },
        step2: {
          title: "時間段",
          description: "你可以在這裏改變圖表中顯示的時間段"
        },
        step3: {
          title: "趨勢線",
          description:"你可以點擊這裏選擇是否在圖表中顯示趨勢線",
        },
        step4: {
          title: "身體體征",
          description: "完整的身體體征的最新測量結果可以在這裏查看"
        },
        step5: {
          title: "詳細信息",
          description: "你可以通過點擊這個按鈕來查看每個身體體征的詳細信息"
        },
        step6: {
          title: "分享",
          description: "通過點擊這個按鈕來分享你的身體體征測量結果"
        },
        step7: {
          title: "一般資訊",
          description: "點擊這個按鈕，可以看到每個身體體征的一般資訊。"
        }
      }
    },
    help_content: {
      disclaimer: {
        title: "免責聲明",
        p1: "本系統是以攝像頭為基礎的非接觸式健康監測方案，可測量生命體徵如心率、呼吸頻率、血壓和血氧飽和度等。 捕捉影像只會作計算之用，並不會存儲任何影片片段。",
        p2: "WVS適合不需要持續或密集健康監測的個人用戶，且不能用作測量生命體徵的唯一方式或取代與醫學專家的諮詢。系統的生命體徵數據不適用於醫療目的， 如果您有任何進一步的查詢，請聯繫專業的醫療人員。"
      },
      setup: {
        title: "設置指南",
        dos: {
          title: "掃描進行時，請...",
          p1: "爲了更好的結果和使用體驗，請按以下指示完成掃描：",
          1: "將攝像頭放置於視線水平附近",
          2: "不要搖晃設備",
          3: "在有充足光源的地方進行掃描，確保光線（最好室内光源）平均照到臉上",
          4: "請避免背光",
          5: "請保持臉部在綠框中間及面向鏡頭",
          6: "請確保和鏡頭之間有合適的距離"
        },
        donts: {
          title: "掃描進行時，請不要...",
          1: "不要帶口罩",
          2: "不要帶帽子",
          3: "不要被任何東西遮擋臉部",
          4: "臉上不要有不均勻的光線 （例如陽光）",
          5: "不要談話",
          6: "身體不要大幅度移動",
          7: "不要把頭轉向旁邊"
        },
        low_accuracy: {
          title: "低準確率例子",
          1: "環境太暗或太光",
          2: "光線不均或背光",
          3: "佩戴口罩或帽子或被東西遮擋臉部",
          4: "沒有面向鏡頭",
          5: "與鏡頭的距離太遠",
          6: "與鏡頭的距離太近",
        },
      },
      operation: {
        title: "操作建議",
        ground_truth: {
          title: "請先使用接觸式設備測量數據",
          p1: "在激活 WVS 系統之前，用戶應先用接觸式脈搏血氧儀作量度，再將 WVS 系統的結果與接觸式脈搏血氧儀的結果進行比較，以得知結果的可參考性。",
          p2: "請按照以下步驟以打開和操作接觸式脈搏血氧儀：",
          1: "擠壓設備背面將其打開。",
          2: "將食指或無名指插入設備，顯示屏朝上。 慣用右手的使用者，請使用右手食指或無名指。 慣用左手的使用者，請使用左手食指或無名指。",
          3: "指尖應觸碰到設備內部的手指擋塊。 如果手指沒有正確定位，請從步驟 1 再次開始。",
          4: "該設備的顯示屏上將顯示您的生命體徵",
          p3: `有關如何使用接觸式設備的更詳細說明，請參閱<a href="https://techdocs.masimo.com/globalassets/techdocs/pdf/lab-10169a_master.pdf">此處</a>的設備手冊。`
        },
        scanning: {
          title: "健康掃描步驟",
          p1: "使用 WVS 掃描系統的步驟如下：",
          1: "掃描開始前，先選擇光源充足的地方舒適地就座，確保光線（最好是室内光源）平均地照到臉上。然後將電腦放置在穩定的平面上，避免設備搖晃。",
          2: "點擊 WVS 系統左側導覽列上的主頁按鈕（第一個圖標），再點擊右上角的綠色「開機」按鈕以啟動相機和面部掃描儀系統。 該頁面應顯示「正在啟動... 」，如下圖所示。如提示允許 WVS 使用您的相機，請點擊允許。",
          caption_2: "生命體徵掃描儀啟動中",
          3: "系統準備就緒後將啟動攝像頭並顯示鏡頭畫面，同時顯示一個綠色框代表檢測到的面部範圍。請確保身體和鏡頭之間有合適的距離，保持臉部在綠框中間及面向鏡頭。",
          caption_3: "系統已啟動",
          4: "點擊綠色圓圈「開始」按鈕以開始掃描程序。 在開始掃描之前，您需要遵循掃描儀中顯示的條件。 滿足條件後，屏幕會出現三秒倒計時，表示掃描即將開始。",
          caption_4: "掃描啟動條件",
          5: "掃描進行時請跟隨綠色框下方綠色圓圈中出現的呼吸指示調整呼吸。 呼吸指示將在「吸氣」和「呼氣」之間切換，如下圖所示，您可從掃描進度圓圈中看到剩餘所需的時間。",
          note_5: "注意：如呼吸速度與指示不吻合，掃描不會檢測到錯誤，但仍建議跟隨呼吸指示以正常靜息速度呼吸。",
          caption_5: "掃描開始",
          6: "掃描會在30秒內完成，並在頁面上顯示生命體徵結果。請等待掃描完成以查看結果。",
          caption_6: "基本生命體徵",
          7: "若您想瀏覽詳細的掃描報告，請點擊導覽列上的第二個圖示前往數據板。",
          caption_7: "掃描結果概覽",
          laptop: "使用手提電腦",
          phone: "使用電話",
          pc: "使用桌上型電腦"
        }
      }
    },
    scan_tutorial: {
      title: "Vitals™ 掃描快速指南",
      dos: "請......",
      donts: "請不要......",
      step1: {
        title: "保持身體處於綠色邊框內",
        paragraph: "點擊開始按鈕後，屏幕上將出現綠色人形邊框。 邊框將指引您到正確的位置和距離使掃描更有效。 請確保您處於綠色邊框內。",
        do1: "將相機放置在視線水平附近",
        do2: "將臉部保持在邊框內並面向相機",
        do3: "確保您和相機之間距離適當",
        dont1: "不要坐在離設備太遠或太近的位置",
      },
      step2: {
        title: "確保相機平穩",
        paragraph: "相機搖晃可能會影響掃描的閱讀。請確保設備被放在平穩的位置並儘量減少移動。",
        do1: "將設備放在平穩的表面上",
        do2: "避免晃動設備",
        dont1: "不要談話",
        dont2: "身體不要大幅度移動 "
      },
      step3: {
        title: "在相機上顯示整張臉",
        paragraph: "確保您的臉完整顯示在相機上。 拿走任何會遮擋臉部的東西。",
        do1: "面向相機",
        do2: "確保您的臉沒有被帽子、連帽衫、口罩、頭髮等遮住。",
        dont1: "不要戴任何會遮住臉的東西（帽子、連帽衫、口罩）",
        dont2: "不要側過臉"
      },
      step4: {
        title: "E確保臉上的光線均勻充足",
        paragraph: "不均勻的光線可能會影響閱讀的準確性。 建議使用室內照明以更容易確保照明正確。",
        do1: "選擇光線充足的區域，確保光線（最好是室內光線）均勻地照射在臉上",
        dont1: "臉上不要有不均勻的光線（例如：陽光）",
        dont2: "背光"
      }
    },
    health_report: {
      title: "個人健康報告",
      excellent: "出色",
      poor: "不佳",
      age: "年齡",
      sex: "性別",
      email: "電郵",
      phone_number: "電話號碼",
      female: "女",
      male: "男",
      height: "身高",
      weight: "體重",
      bmi: "BMI",
      general_wellness: "綜合健康分數",
      wellness: {
        title: "整體健康",
        general_wellness: {
          title: "綜合健康",
          definition: "綜合健康分數是通過核心生命體徵和精神壓力水平的加權分數來計算的。",
          range: "正常的綜合健康分數為80-100",
          result: "你的綜合健康分數是{value}"
        },
        stress: {
          title: "壓力水平",
          definition: "壓力是一種情緒或身體緊張的感覺。 它可能來自任何讓你感到沮喪、憤怒或緊張的事件或想法。",
          range: "正常的靜息壓力等級為 0-2。",
          result: "你的壓力水平等級為{value}."
        }
      },
      respiratory: {
        title: "呼吸系統",
        respiratory_rate: {
          title: "呼吸率",
          definition: "呼吸頻率為一個人每分鐘呼吸的次數。 呼吸頻率可能會隨著發燒、疾病、壓力和其他醫療狀況而上升。",
          range: "成年人靜息時正常呼吸頻率為每分鐘 12 至 16 次",
          result: "您的呼吸頻率為每分鐘 {value}次。"
        },
        spo2: {
          title: "血氧飽和度",
          definition: "血氧飽和度量度血紅細胞從肺部攜帶到身體其他部位的氧氣量。 低血氧症的常見症狀包括頭痛、心率加快、氣短等。",
          range: "大多數人的血氧飽和度為95% 或以上。",
          result: "你的血氧飽和度為 {value}%."
        }
      },
      cardiovascular: {
        title: "心血管系統",
        heart_rate: {
          title: "心率",
          definition: "心率是每分鐘心臟跳動的次數 (bpm)。 高心率可能是由荷爾蒙問題、焦慮、大量攝入咖啡因/酒精、貧血等引起的。",
          range: "正常的靜息心率範圍為每分鐘60-100次。",
          result: "你的心率為每分鐘{value}次",
        },
        hrv_sdnn: {
          title: "心率變異度SDNN值",
          definition: "心率變異度是每次心跳之間時間變化的量度。 SDNN 是正常竇性搏動的 IBI 的標準偏差，以毫秒(ms) 為單位。低心率變異度可能是由睡眠不足的壓力造成的。",
          range: "100毫秒以上的心率變異度SDNN 是健康的。",
          result: "您的心率變異度 SDNN 為 {value} 毫秒。"
        },
        hrv_ibi: {
          title: "心率變異度IBI值",
          definition: "心率變異度是每次心跳之間時間變化的量度。 IBI 是心臟的搏動間期。 在心臟功能正常的情況下，每個 IBI 值會隨心跳改變。",
          range: "典型的心率變異度 IBI 值範圍為 500-1300 毫秒。",
          result: "您的心率變異性 IBI 為 {value} 毫秒。",
        },
        systolic_bp: {
          title: "收縮壓",
          definition: "收縮壓是由心肌收縮引起的肱動脈的峰值壓力。 低血壓可能是由脫水、懷孕、休克、內分泌失調等引起的。",
          range: "理想的收縮壓在 90-120mmHg 之間。",
          result: "您的收縮壓為 {value} mmHg。",
        },
        diastolic_bp: {
          title: "舒張壓",
          definition: "舒張壓是當您的心肌放鬆時您的肱動脈的壓力量。 高血壓可能是由不健康的生活方式或某些健康狀況引起的，例如糖尿病和肥胖。",
          range: "理想的舒張壓在 60-80 mmHg 之間。",
          result: "您的舒張壓為 {value} mmHg。"
        },

        },
        disclaimer: {
          title: "免責聲明",
          p1: "以攝像頭為基礎的健康監測方案設計用於非接觸式測量健康生命體徵，例如心率、呼吸頻率、血壓和 O2 飽和度。 視頻片段將通過設備的攝像頭捕捉，但不會存儲任何視頻片段。",
          p2: "該設備不能用作個人健康生命體徵測量的唯一方法，也不能取代與醫學專家的諮詢。 而且測量不用於醫療目的。 如果您有任何進一步的健康查詢，請立即聯繫醫療保健專業人員或其他醫學專家。",
          powered_by: '由',
          powered_by_2: '提供技術支持'
      },
    }
  },
};

module.exports = locales;
