interface cameraParameters {
    videoWidth: number,
    videoHeight: number,
    videoFrameRate: number,
}

export enum loginOption {
    firebase, 
    aws,
    alibabacloud,
}

export enum applicationOption {
    b2c, 
    b2b,
}

interface VitalsConfig {
    login: loginOption,
    application: applicationOption,
    scanTime: number,
    cameraParameters: cameraParameters,
}

export const vitalsConfig: VitalsConfig = {
    login: loginOption.aws,
    application: applicationOption.b2b,
    scanTime: 30,
    cameraParameters: {
        videoWidth: 1920,
        videoHeight: 1080,
        videoFrameRate: 30,
    },
}
