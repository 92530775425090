<template>
    <div class="signal-box-container">
        <div class="signal-box" v-if="scanParameters && conditions">
            <!-- <div v-for="n in 4">
            </div> -->
            <div class="title">
                {{ t('message.scan_quality') }}:
            </div>
            <div>
                <span v-for="condition in conditions" :key="condition">
                    <img :src="imgSrc(condition)" class="stars"/> 
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { useI18n } from "vue-i18n";

export default
  {
    name: 'signal-quality-box',
    props: {
        scanParameters: null,
    },
    setup() {
        const { t, locale } = useI18n();
        return { t, locale }
    },
    mounted() {
        this.getConditions();
    },
    data() {
        return {
            conditions: null,
        }
    },
    watch: {
        scanParameters() {
            this.getConditions();
        }
    },
    methods: {
        imgSrc(condition) {
            if (condition) { return require("./assets/colour-star.svg"); }
            else { return require("./assets/grey-star.svg"); }
        },
        getConditions() {
            if (this.scanParameters !== null) {
                let numberOfTotalStars = 5;
                let numberOfGoodStars = Math.floor(this.scanParameters.signalQuality / 100 * numberOfTotalStars);
                let numberOfBadStars = numberOfTotalStars - numberOfGoodStars;
                let conditions = Array()
                for (let i=0; i<numberOfGoodStars; i++) { conditions.push(true) }
                for (let i=0; i<numberOfBadStars; i++) { conditions.push(false) }
                this.conditions = conditions
            } else {
                this.conditions = null;
            }
        }
    },
    computed: {
        parameters(){
            if (this.scanParameters && this.scanParameters.signalQuality) {
                return this.scanParameters.signalQuality.toString()
            }
            return "null"
        },
    }
}
</script>

<style scoped>
.signal-box-container {
    position: fixed;
    width: calc(100% - 87px);
    left: 87px;
    top: 16%;
}
.signal-box {
    position:relative;
    display: grid;
    width: 38%;
    max-width: 350px;
    margin: auto auto;
    background: rgba(50, 50, 50, 0.6);
    backdrop-filter: blur(200px);
    border-radius: 15px;
    text-align: center;
    font-size: large;
    padding: 8px;
}
.title {
    font-size: x-large;
    text-align: center;
    color: #F5B33B;
}
.stars {
    max-width: 40px;
}

@media (max-width: 700px) {
  .signal-box-container {
    position: fixed;
    width: 100%;
    left: 0px;
    top: 15%;
  }
  .signal-box {
    position:relative;
    width: 40%;
    margin: auto auto;
    background: rgba(50, 50, 50, 0.6);
    backdrop-filter: blur(200px);
    border-radius: 15px;
    text-align: center;
  }
  .title{
    font-size: medium;
    text-align: center;
    color: #F5B33B;
  }
  .stars{
    max-width: 20px;
  }
}

</style>


