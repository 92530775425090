<template>
    <data-hud class="data-hud" :title="t('vital_signs.heartRate.title')" :text="text" unit="bpm" :data="data"/>
</template>

<script>
import DataHud from "./data-hud.vue"
import { useI18n } from 'vue-i18n';

export default 
  {
    name: 'heart-rate',
    props: {
        data: Number
    },
    data() {
        return {
            text: "Heart rate is the number of beats of the heart per minute (bpm). High level of heart rate  may be caused hormonal problems, anxiety, heavy caffeine/alcohol consumption, anemia, etc. "
        }
    },
    setup() {
    const { t, locale } = useI18n()
    return {t, locale}
    },
    components: { DataHud }
}
</script>

<style scoped>
.data-hud {
    position: relative;
}
@media (max-width: 1044px) {
    .data-hud {
        position: relative;
        left: auto;
        top: auto;
    }
}
</style>


