<template>
  <div id="webcamContainer" ref="videoContainer">
    <video ref="video" autoplay="true" playsinline/>
    <face-mesh-view
      v-if="
        lastResult !== undefined && enable == true
      "
      :result="lastResult"
      :aspectRatio="aspectRatio"
      :videoId="videoId"
    />

    <information-box :enable="vsStatus == 'measuring' && startMeasureTime !== null"></information-box>
    <div class="information-box-container">
      <div
        v-if="vsStatus == 'measuring' && startCountdown"
        class="instruction-box"
      >
      <p class="text">
        <!-- Scanning starting in {{ countdown }}, stay still. -->
        {{ t("instruction.count_down1") }}{{ countdown
        }}{{ t("instruction.count_down2") }}
        </p>
      </div>
    </div>

    <conditions-hud
      v-if="conditions && startMeasureTime == null && vsStatus == 'measuring'"
      :conditions="conditions"
    />
        <img v-if="debugCamera" class="debugFace" ref="facePhoto" />
  </div>
</template>

<script>
import { Camera } from "./camera.js";
import { FaceMeshDetection } from "./face-landmarks-detection.js";
import FaceMeshView from "./face-mesh-view.vue";
import adapter from "webrtc-adapter";
import { newUserManager } from "../../user/company-user-manager.ts";
import { VitalSignEngine, ServerId, GetHealthStage } from "vital-sign-engine";
import {
  crop,
  resetMovementArray,
  checkConditions,
} from "../../plugin/plugin_helpers.js";
import ConditionsHud from "./conditions-hud.vue";
import { FaceDetector } from "../../plugin/face_detection_module.js";
import { useI18n } from "vue-i18n";
import { vitalsConfig, loginOption } from "../../vitals-config"
import InformationBox from "./information-box.vue"
import Logger from '@/common/logger.js';

class FPS {
  frameCount = 0;
  start = new Date();
  name = "noname";
  constructor(name) {
    this.name = name;
  }

  get current() {
    this.frameCount = this.frameCount + 1;
    return (this.frameCount / this.elapsed).toFixed(2);
  }

  get elapsed() {
    return (new Date().getTime() - this.start.getTime()) / 1000;
  }

  log(debug = false) {
    if (debug) {
      Logger.log(`[FPS ${this.name}] ${this.current}fps`);
    }
    this.current;
  }
}

export default {
  name: "face-detection-view",
  props: {
    enable: { default: false, type: Boolean },
    vsStatus: { type: String, default: "idle" },
    debugFlag: { type: Boolean, default: true },
    device: String
  },
  emits: ["finishMeasurement", "updateTimeLeft", "detectionResult", "scanParameters", "startQuiz"],
  setup() {
    const { t, locale } = useI18n();
    return {
      t,
      locale,
    };
  },
  components: {
    // BoundingBoxView,
    FaceMeshView,
    ConditionsHud,
    InformationBox,
},
  data() {
    return {
      videoHeight: null,
      videoWidth: null,
      videoFrameRate: 30,
      video: null,
      camera: null,
      faceMesh: null,
      faceDetectionTimer: null,
      lastResult: undefined,
      aspectRatio: undefined,
      finishMeasurement: false,
      startMeasureTime: null,
      scanTime: null,
      vitalResults: null,
      healthResults: null,
      timeLeft: null,
      startWebcam: false,
      conditions: undefined,
      startCountdown: false,
      countdown: 3,
      faceDetector: undefined,
      facialSkinAge: 0,
      debugCamera: false,
      transform: "scaleX(-1)",
      videoId: ''
    };
  },

  async mounted() {
    window["__face_detection_view__"] = this;
    if (this.faceDetector === undefined) {
      this.faceDetector = new FaceDetector();
    }

    let serverId;
    switch (vitalsConfig.login) {
      case (loginOption.firebase):
        serverId = ServerId.RemoteProdEnterprise;
        break;
      case (loginOption.aws): 
        serverId = ServerId.AwsProdEnterprise;
        break;
      default: 
        serverId = ServerId.AwsProdEnterprise;
        break;
    }
    VitalSignEngine.configure({ 
      serverId: serverId,
      apiKey:"3m46C76Tka5wfpsxhZZQk1nhE49I9smM5zTag7PN"
    });
    this.scanTime = vitalsConfig.scanTime;

    if ( !newUserManager.currentUser ) { return; }
    if (!newUserManager.currentUser.currentUser) { return; }
    this.updateConfig();

    // console.log("VitalsPlugin configuration: ", this.config);
    // this.plugin = new VitalsPlugin(this.config);
    if (this.enable) {
      this.startCamera();
    }
  },
  watch: {
    videoId() {
      this.changeTransform();
    },
    enable(newValue) {
      if (newValue) {
        this.startCamera();
      } else {
        this.stopCamera();
      }
    },
    device(newValue, oldValue) {
      if(this.enable && oldValue != '') {
        this.stopCamera();
        this.startCamera();
      }
    },
    $route(newRoute) {
      if (newRoute.fullPath === "/home" && this.enable) {
        this.startCamera();
      } else {
        this.stopCamera();
      }
    },
    vsStatus(newValue) {
      if (newValue == "measuring") {
        // VitalSignEngine.start();
        // this.startMeasureTime = new Date();
        // this.startCheckingTime = new Date();
        Logger.log(
          `face-detection-view status [${this.vsStatus}] @`,
          "",
          this.startMeasureTime
        );
        
        this.facialSkinAge = 0;
      } else {
        // reset the flags
        this.finishMeasurement = false;
        // let now = new Date();
        // console.log(`face-detection-view status [${this.vsStatus}] @`, now);
      }
    },
    lastResult(newResult) {
      this.$emit("detectionResult", newResult);
    },
    vitalResults() {
      this.$root.$data.vitalSignObject = this.vitalResults;
    },
  },
  methods: {
    changeTransform() {
          const browser = adapter.browserDetails.browser;
          var facingMode;
          if(browser == 'firefox') {
          facingMode = document.getElementsByTagName('video')[0].srcObject.getVideoTracks()[0].getSettings().facingMode;
          }
          else {
          facingMode = document.getElementsByTagName('video')[0].srcObject.getVideoTracks()[0].getCapabilities().facingMode;
          }
          if(facingMode && facingMode[0] === 'environment') {
              this.transform = 'none';
          } else {
              this.transform = 'scaleX(-1)';
          }
    },
    updateConfig() {
      const currentUser = newUserManager.currentUser.currentUser;
      var userSettings = currentUser.userSettings;
      var companySettings = newUserManager.currentUser.companySettings;
      let userInfo = {}
      if (companySettings === undefined) {
        return
      }
      Object.assign(userInfo, userSettings)
      userInfo.userId = companySettings.UID;
      userInfo.planExpiryDate = companySettings.planExpiryDate;
      userInfo.planType = companySettings.planType;
      userInfo.age = userSettings.age;
      delete userInfo.name
      delete userInfo.email
      delete userInfo.phoneNumber
      delete userInfo.profileImage
      this.config = {
        resolution: [
          vitalsConfig.cameraParameters.videoWidth,
          vitalsConfig.cameraParameters.videoHeight,
        ],
        userInfo: userInfo,
      };
    },
    setCameraParameters() {
      try {
        this.videoWidth = vitalsConfig.cameraParameters.videoWidth;
        this.videoHeight = vitalsConfig.cameraParameters.videoHeight;
        this.videoFrameRate = vitalsConfig.cameraParameters.videoFrameRate;
      } catch (err) {
        console.error(err);
        this.videoWidth = 1280;
        this.videoHeight = 720;
        this.videoFrameRate = 60;
      }
    },
    startCamera() {
      this.setCameraParameters();
      this.startWebcam = true;

      let browser = adapter.browserDetails.browser;
      Logger.log(
        `start Camera ${this.device} in ${browser} ${this.videoHeight}x${this.videoWidth} @ ${this.videoFrameRate} fps`
      );

      if (["chrome"].includes(browser)) {
        // these browsers need time to initiate the camera
        setTimeout(() => {
          this.faceDetectionProcess();
        }, 300);
      } else {
        this.faceDetectionProcess();
      }
    },
    stopCamera() {
      VitalSignEngine.stop();

      resetMovementArray();

      this.video = this.$refs.video;
      if (this.video) {
        this.stopVideo();
      }
      if (this.camera) {
        this.camera.stop();
      }
      if (this.faceMesh !== undefined) {
        try {
          delete this.faceMesh;
        } catch (err) {
          console.error(`cannot delete faceMesh @ ${err}`);
        }
      }
      this.timeLeft = null;
      this.$emit("updateTimeLeft", this.timeLeft);
      this.startWebcam = false;
      this.startMeasureTime = null;
      this.conditions = undefined;
    },
    async stopVideo() {
      if (this.$refs.video.srcObject) {
        this.$refs.video.srcObject
          .getVideoTracks()
          .forEach((track) => track.stop());
      }

      /* Typescript VSE Stop*/
      VitalSignEngine.stop();
      clearInterval(this.faceDetectionTimer);
      this.$emit("scanParameters", null)
      Logger.log("stop Camera");
    },
    faceDetectionProcess() {
      /* for debug only: add dummy data */
      // if (newUserManager.currentUser.currentUser.lastHealth) { 
      //   this.healthResults = newUserManager.currentUser.currentUser.lastHealth; 
      //   console.log("update healthResult (debug)", this.healthResults);
      // }

      const video = this.$refs.video;

      const canvas = document.createElement("canvas");
      if (this.videoHeight == 0 || this.videoWidth == 0) {
        this.setCameraParameters();
      }
      canvas.width = this.videoWidth;
      canvas.height = this.videoHeight;
      let fps = new FPS("camera");
      let okToScan = false;

      const camera = new Camera(video, {
        height: this.videoHeight,
        width: this.videoWidth,
        deviceId: this.device,
        onFrame: async () => {
          // Force set resolution
          // TODO: find a way to optimize this
          if (
            this.videoWidth != video.videoWidth ||
            this.videoHeight != video.videoHeight
          ) {
            this.videoHeight = video.videoHeight;
            this.videoWidth = video.videoWidth;
          }

          fps.log();
          if (this.vsStatus !== "measuring") {
            return;
          }
          if (this.vsStatus == "measuring") {
            let currentTime = new Date();
            let videoFrame = canvas.getContext("2d").canvas;

            // all conditions are fulfilled, start counting down and scan 
            if ( okToScan && this.startMeasureTime == null && !this.startCountdown ) {
              this.countdown = 3;

              this.startCountdown = true;

              var interval = setInterval(() => {
                this.countdown--;
                if (this.countdown < 0) {
                  this.startCountdown = false;
                  this.startMeasureTime = new Date();
                  VitalSignEngine.start();
                  Logger.log("starting measurement", "", this.startMeasureTime);
                  clearInterval(interval);
                  this.updateConfig();  // update configuration to include latest userInfo
                  this.countdown = 3;
                  this.$emit('startQuiz');
                }
                if (!okToScan) {
                  this.startCountdown = false;
                  clearInterval(interval);
                  this.countdown = 3;
                }
              }, 1000);

              this.facialSkinAge = 0;  // reset the facial skin age
              var ageEstimationInterval = setInterval(async () => {
                // facial skin age estimation during the 3 seconds count down
                const detectionWithAgeAndGender = await this.faceDetector.estimateAge(videoFrame);
                if (detectionWithAgeAndGender !== null) {
                  this.facialSkinAge =
                    0.8 * this.facialSkinAge +
                    0.2 * detectionWithAgeAndGender.age;
                }
                if ( this.startMeasureTime ) {
                  clearInterval(ageEstimationInterval)
                  // console.log("estimated age: ", this.facialSkinAge)
                }
              }, 200);
            }

            let stage = undefined;
            if (this.lastResult !== undefined && this.countdown > 0) {
              canvas
                .getContext("2d")
                .drawImage(video, 0, 0, canvas.width, canvas.height);

              let faceImage = crop(videoFrame, this.lastResult.faceBox);

              // check condition before the scan is started
              if (this.startMeasureTime == null) {
                const conditions = await checkConditions(
                  this.lastResult.videoFrame,
                  this.lastResult.faceBox
                );
                okToScan = Object.values(conditions).every((item) => item);

                this.conditions = conditions;
              }

              // SCAN PROCESS //
              if (okToScan) {
                let data = {
                  //"videoFrame": imageData.data,
                  videoFrame: videoFrame.data,
                  faceBox: this.lastResult.faceBox,
                  landmarks: this.lastResult.landmarks,
                  videoFrameInfo: {
                    width: this.lastResult.videoFrame.width,
                    height: this.lastResult.videoFrame.height,
                  },
                  //faceImage: faceImage,
                  faceImage: faceImage.toDataURL("image/png"),
                  userInfo: this.config.userInfo,
                  timestamp: new Date().getTime() / 1000,
                };

                /* NEW TSX PLUGIN */
                const healthResult = await VitalSignEngine.getHealth({
                  faceBox: data.faceBox,
                  landmarks: data.landmarks,
                  videoFrameInfo: data.videoFrameInfo,
                  faceImage: data.faceImage,
                  userInfo: data.userInfo,
                  /*
                      faceBox: this.lastResult.faceBox,
                      landmarks: this.lastResult.landmarks,
                      videoFrameInfo: videoFrameInfo,
                      faceImage: faceImage.toDataURL("image/png"),
                      userInfo: this.config.userInfo,
                  */
                });
                stage = healthResult?.stage
                if (
                  healthResult.stage >= 2 &&
                  healthResult.health &&
                  this.timeLeft < 8
                ) {
                  let facialSkin = {"facialSkinAge": Math.max(18, this.facialSkinAge - 2) } // 2 years old younger ;)}
                  // healthResult.health['facialSkin'] = facialSkin; 
                  let health = healthResult.health;
                  health['facialSkin'] = facialSkin;
                  this.healthResults = healthResult.health;
                  // this.vitalResults = healthResult.health; // depreciated
                }
                if (this.startMeasureTime) {
                  this.timeLeft =
                    this.scanTime -
                    (data.timestamp - this.startMeasureTime.getTime() / 1000);
                  if (healthResult.stage < 3 && this.timeLeft < 0) {
                    this.timeLeft = 0.01;
                  } // force the app to wait for the last request to be processed.
                  this.$emit("updateTimeLeft", this.timeLeft);
                }
              }
            }

            if (this.startMeasureTime && stage === GetHealthStage.Idle) {
              this.finishMeasurement = true;
              this.$emit("finishMeasurement");
              this.startMeasureTime = null;
              // this.startCheckingTime = null;
              this.timeLeft = null;
              this.$emit("updateTimeLeft", this.timeLeft);
              Logger.log(
                `face-detection-view finish ${this.scanTime}-sec measurement @ ${currentTime}`
              );
              /* OLD plugin */
              // this.plugin.clear();

              /* Typescript VSE Stop */
              VitalSignEngine.stop();

              /* Save the results */
              this.$root.$data.healthObject = this.healthResults;
              newUserManager.currentUser.currentUser.setHealth(this.healthResults)
              newUserManager.currentUser.uploadScanResults();
              // newUserManager.currentUser.uploadHealth();
              if (newUserManager.currentUser.currentUser.lastHealth.scanParameters) { 
                this.$emit("scanParameters", newUserManager.currentUser.currentUser.lastHealth.scanParameters)
              }

            }
          }
        },
      });
      /* Typescript VSE Start */
      camera.start()
      .then(() => {
        this.videoId = this.$refs.video.srcObject.id;
      });
      this.faceDetector.warmUp();

      /* Face Detection Loop */
      let maxFaceMeshFPS = 5;
      let busy = false;
      const canvasLowRes = document.createElement("canvas");
      let faceDetector = ["mediapipe", "face-api"][0];
      switch (faceDetector) {
        case "mediapipe": {
          let faceMeshFPS = new FPS("mediapipe");

          // mediapipe face detector
          const faceMesh = FaceMeshDetection((result) => {
            this.lastResult = result;
            faceMeshFPS.log(false);
          });
          this.faceDetectionTimer = setInterval(async () => {
            if (this.faceDetectionTimer && fps.frameCount > 0 && !busy) {
              busy = true;
              // let dtStart = new Date()
              if (document.getElementsByTagName('video')[0].srcObject) {
                const videoSettings = document.getElementsByTagName('video')[0].srcObject.getVideoTracks()[0].getSettings();
                const aspectRatio = videoSettings.width/videoSettings.height;
                canvasLowRes.width = aspectRatio > 1 ? 320 : 320 * aspectRatio;
                canvasLowRes.height = canvasLowRes.width / aspectRatio

                let ctx = canvasLowRes.getContext("2d")
                ctx.drawImage(video, 0, 0, this.videoWidth, this.videoHeight, 0, 0, canvasLowRes.width, canvasLowRes.height)
                if (this.debugCamera) {
                  this.$refs.facePhoto.src = canvasLowRes.toDataURL()
                }

                await faceMesh.send({ image: canvasLowRes });
                // let dtEnd = new Date()
                // let fpsFaceMesh = (1000 / (dtEnd.getTime() - dtStart.getTime())).toFixed(2)
                // console.log(`[mediapie] ${fpsFaceMesh} fps`)
                busy = false;
              }
            }
          }, 1000 / maxFaceMeshFPS);

          this.faceMesh = faceMesh;
          break;
        }
        case "face-api": {
          // face-api face detector
          let faceMeshFPS = new FPS("face-api");
          canvasLowRes.width = 1280;
          canvasLowRes.height = 720;
          this.faceDetectionTimer = setInterval(async () => {
            if (this.faceDetectionTimer && fps.frameCount > 0 && !busy) {
              busy = true;
              faceMeshFPS.log(false);
              // let dtStart = new Date()
              canvasLowRes
                .getContext("2d")
                .drawImage(
                  video,
                  0,
                  0,
                  this.videoWidth,
                  this.videoHeight,
                  0,
                  0,
                  canvasLowRes.width,
                  canvasLowRes.height
                );
              this.lastResult = await this.faceDetector.detectFace(
                canvasLowRes
              );
              // let dtEnd = new Date()
              // let fpsFaceMesh = (1000 / (dtEnd.getTime() - dtStart.getTime())).toFixed(2)
              // console.log(`[face-api] ${fpsFaceMesh} fps`)
              busy = false;
            }
          }, maxFaceMeshFPS);
          break;
        }
        default: {
          break;
        }
      }
      this.camera = camera;
    },
  },
};
</script>

<style scoped>
#webcamContainer {
  /* margin-right:87px; */
  position: relative;
  height: 100vh;
  overflow: hidden;
}
.debugFace {
  position: absolute;
  left: 10%;
  top: 10%;
  min-width: 100px;
  min-height: 100px;
}
video {
  -webkit-transform: v-bind(transform);
  transform: v-bind(transform);
  object-fit: cover;
  width:100%;
  height:100%;
  z-index: -1;
}
</style>
