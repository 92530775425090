<template>
    <BIBOCircle
    :style="{width: radius*1.5, height: radius*1.5, 'border-radius': radius}"
    :state="state"
    :radius="radius"/>

    <div id="get-vs-button-container">
        <button ref="button" v-if="state == 'idle'" id="get-vs-button"
            class="btn btn-success"
            :style="{width: `${radius*2}px`, height: `${radius*2}px`, 'border-radius': `${radius}px`}"
            @click="startGettingVS()">{{ t('button.start') }}
        </button>
        <circular-progress  v-if="state == 'measuring'"
            :radius="radius"
            :progress="progress" 
            :stroke="10"
            :text="Math.round(timeLeft).toString() + 's'" />
    </div>
</template>

<script>
import io from 'socket.io-client'
import api from '../../common/api.js'
import { ref } from 'vue'
import CircularProgress from './circular-progress.vue'
import { useI18n } from 'vue-i18n';
import BIBOCircle from './breathing-circle.vue'

export default {
    name: 'vital-sign-button',
    props: {
        radius: Number
    },
    setup() {
        const { t, locale } = useI18n()

        return {
            t, locale,
            vitalSign: ref(),
            state: ref('idle'),
            timeleft: ref(),
            progress: ref(0)
        }
    },
    created() {
        const socket = io()
        socket.on('vital_sign', (data) => {
            this.vitalSign = data.signal_data
            this.state = data.state
            this.timeLeft = data.time_left
            this.totalTime = data.total_time
            this.progress = Math.round(((this.totalTime - this.timeLeft) / this.totalTime)*100.0)
        });
    },
    methods: {
        startGettingVS() {
            api.startGettingVS()
        }
    },
    components: {
        CircularProgress,
        BIBOCircle
    }
}
</script>

<style scoped>
#get-vs-button-container {
  position: absolute;
  left: 0px;
  width: 100%;
  bottom: 64px;
  display: flex;
  justify-content: center;
}
#get-vs-button {
    opacity: 1.0;
}
.btn-circle {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    padding: 10px 16px;
    font-size: 16px;
    text-align: center;
}
</style>