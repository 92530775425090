<template>
    <data-hud class="data-hud" :title="t('vital_signs.facialSkinAge.title')" :text="text" unit="" :data="data"/>
</template>

<script>
import DataHud from "./data-hud.vue"
import { useI18n } from 'vue-i18n';

export default
  {
    name: 'facial-skin-age',
    props: {
        data: Number
    },
    data() {
        return {
            text: "Facial skin is particularly thin and the most visible part of the body. Like all skin, face skin serves as an effective barrier against the external environment. Lify antioxidant drinks can help combat oxidative stress which is beneficial for skin health."
        }
    },
    setup() {
    const { t, locale } = useI18n()
    return {t, locale}
    },
    components: { DataHud }
}
</script>

<style scoped>
.data-hud {
    position: relative;
}
@media (max-width: 768px) {
    .data-hud {
        position: relative;
        left: auto;
        top: auto;
    }
}
</style>


