<template>
    <div class="result-card container">
        <div class="row justify-content-center ">
            <img :src="require(''+content.image)" alt="" class="image">
        </div>
        <div class="top-content">
            <div class="row justify-content-center title">
                {{content.title}}
            </div>
            <div class="row justify-content-center subtitle">
                {{content.subtitle}}
                <i class="fw-normal">{{content.note ? content.note : ''}}</i>
            </div>
        </div>
        <div class="row justify-content-center description">
            {{content.description}}
        </div>
        <div class="row justify-content-center">
            <a :href="`https://lifywellness.com/collections/all-blends/products/${name}`" target="_blank">
                <button class="btn">
                        Brew Me 
                </button>
            </a>
        </div>
    </div> 
</template>

<script>
export default {
    setup() {
        
    },
    props: {
        content: Object,
        name: String,
    }
}
</script>

<style scoped>
.result-card {
    height: 433px;

    background: #FFFFFF;
    /* shadow #1 */

    box-shadow: 1px 10px 10px rgba(102, 62, 51, 0.02), 10px 20px 20px rgba(102, 62, 51, 0.04), 20px 30px 30px rgba(102, 62, 51, 0.06), 55px 65px 44px rgba(197, 104, 78, 0.13);
    border-radius: 24px;
    padding: 13px 30px;
}
img {
    width: 163px;
    height: 140px;
    object-fit: cover;
    border-radius: 50%;
}
.title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18.9216px;
    line-height: 132%;
    letter-spacing: 0.1971px;
    padding-top: 15px;
}
.subtitle {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14.1912px;
    line-height: 132%;
    /* or 19px */

    text-align: center;
    letter-spacing: 0.1971px;
    padding: 0 10px;
    color: #12121F;
}
.description {
    padding: 5px 20px;
    margin-bottom: 10px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 11.0376px;
    line-height: 14px;
    /* or 129% */

    text-align: center;
    letter-spacing: 0.1971px;

    color: #AFAFBD;
    height: 105px;
}
.top-content {
    height: 100px;
}
a {
    background: #FE805C;
    border-radius: 18.9216px;
    height: 31.5360050201416px;
    width: 111.95281219482422px;
}
button {
    text-decoration: none;
    font-family: Montserrat;
    font-size: 11px;
    font-weight: 700;
    letter-spacing: 0.19710002839565277px;
    text-align: center;
    color: white;
    width: 100%;
    height: 100%;
}
@media (max-width: 600px) {
    img {
        width: 113px;
        height: 90px;
    }    
    .title {
        font-size: 16px;
    }
    .subtitle {
        font-size: 12px;
    }
    .description {
        font-size: 11px;
        height: 120px;
    }
    .result-card {
        height: 385px;
        padding: 13px 15px;
    }
}
</style>