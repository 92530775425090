<template>
    <img v-if="fulfilled" src="./assets/checkmark.svg">
    <img v-else src="./assets/crossmark.svg">
</template>

<script>

export default {
    name: 'condtion-icon',
    props: {
        fulfilled: { type:Boolean, default:false }
    },
    setup() {
        
    },
}
</script>

<style scoped>
img {
    width: 14px;
    height: 14px;
}
</style>