<template>
  <div class="sidenav">
    <div id="side-bar">
      <div id="company-logo"></div>
      <home-button id='home-button' class='icon'/>
      <!-- <chart-button id='chart-button' class='icon'/> -->
      <!-- <multi-user-button id='multi-user-button' class='icon'/> -->
      <setting-button id='setting-button' class='icon'/>
      <!-- <email-button id='email-button' class='icon'/> -->
      <!-- <event-button id='event-button' class='icon icon-coming-soon'/> -->
      <help-button id='help-button' class='icon'/>
      <div
        id="logout-button"
        class="btn btn-clear icon"
        data-bs-toggle="modal"
        data-bs-target="#confirmLogoutModal"
      >
      </div>
      <locale-button id='locale-button' class='icon'/>
    </div>
    
      <!-- 
      <chat-button id='chat-button'/>
       -->
  </div>

  <!-- Logout Confirm Modal -->
  <div
    class="modal fade"
    id="confirmLogoutModal"
    tabindex="-1"
    aria-labelledby="confirmLogoutModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="confirmLogoutModalLabel">{{ t('page.logout') }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          {{t('logout_prompt')}}
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
          >
            {{t('option.logout_no')}}
          </button>
          <button
            type="button"
            class="btn btn-danger"
            data-bs-dismiss="modal"
            @click="logout"
          >
            {{t('option.logout_yes')}}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HomeButton from "./home-button.vue";
// import ChartButton from "./chart-button.vue";
// import MultiUserButton from "./multi-user-button.vue";
// import EmailButton from "./email-button.vue";
import SettingButton from "./setting-button.vue";
// import EventButton from "./event-button.vue";
// import ChatButton from "./chat-button.vue";
import HelpButton from "./help-button.vue";
import localeButton from "./locale-button.vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { useI18n } from 'vue-i18n';
import { newUserManager } from "../../user/company-user-manager.ts";

export default {
  setup() {
    const { t, locale } = useI18n()
    return {
      t, locale,
    }
  },
  methods: {
    async logout() {
      await newUserManager.logout();
      this.$router.push("/");
    },
  },
  components: {
    HomeButton,
    // ChartButton,
    // MultiUserButton,
    // EmailButton,
    // EventButton,
    // ChatButton,
    SettingButton,
    HelpButton,
    localeButton,
  },
};
</script>

<style scoped>
.sidenav {
  position: fixed;
  width: 87px;
  height: 100%;
  left: 0px;
  top: 0px;
  overflow-y: auto;
  overflow-x: hidden;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.5);
  z-index: 3;
}
#side-bar {
  display: block;
  height: auto;
  /* overflow: scroll; */
}
#company-logo {
  position: relative;
  /* margin: top right bottom left */
  margin: 13px auto 13px auto; 
  padding: 27px;
  width: 60px;
  height: 60px;
  background-position: center;
  background: url(./assets/icon.svg);
}
.icon {
  display: flex;
  width: 60px;
  height: 60px;
  min-width: 60px;
  min-height: 60px;
  /* margin: top right bottom left */
  margin: 10px auto 10px auto;
  align-items: center;
}
.icon-coming-soon {
  opacity: 0.5;
}
#logout-button {
  position: relative;
  margin: auto auto;
  background-size: 50px 50px;
  background-repeat: no-repeat;
  background-image: url("./assets/logout_icon.svg");
}
#locale-button {
  position: fixed;
  bottom: 2%;
  left: 13px;
  margin: auto auto;
}

@media (max-width: 768px) {
  .sidenav {
    position: fixed;
    width: 100%;
    max-height: 50px;
    bottom: 0px;
    top: auto;
    overflow-x: scroll;
    overflow-y: hidden;
    background: #ffffff;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.5);
  }
  #side-bar {
    /* height: 50px; */
    display: flex;
    width: 100%;
    overflow: auto;
    white-space: nowarp;
  }
  .icon {
    transform: scale(0.9) translateY(-4px);
    margin: 0px 10px 2px 10px;
    padding-left: 7px;
    /* border: 2px solid red; */
  }
  .icon-text {
    display: flex;
    /* position: relative; */
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    /* margin: top right bottom left */
    margin: 5px 10px auto 10px;
    align-items: center;
  }
  #company-logo {
    position: relative;
    background-repeat: no-repeat;
    background-size: 40px 40px;
    /* margin: top right bottom left */
    margin: 5px 0px auto 10px; 
    max-width: 40px;
    width: 40px;
    height: 40px;
    /* display: none; */
  }
  #help-button {
    transform: scale(0.9) translateY(-4px);
  }
  #logout-button {
    margin: 5px 10px auto 10px;
    background-size: 45px 45px;
  }
  #locale-button {
    position: unset;
    bottom: unset;
    margin-right: 20px;
    transform: translateY(-8px);
  }
}
</style>