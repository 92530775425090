<template>
    <data-hud class="data-hud" :title="t('vital_signs.spo2.title')" :text="text" unit="% &emsp;&nbsp;" :data="data"/>
</template>

<script>
import DataHud from "./data-hud.vue"
import { useI18n } from 'vue-i18n';

export default 
  {
    name: 'o2-saturation',
    props: {
        data: Number
    },
    setup() {
        const { t, locale } = useI18n()
    return {t, locale}
    },
    data() {
        return {
            text: "Oxygen saturation is a measure of how much oxygen the red blood cells are carrying from the lungs to the rest of the body. Low level of oxygen saturation may be caused by an underlying illness that affects blood flow or breathing."
        }
    },
    components: { DataHud }
}
</script>

<style scoped>
.data-hud {
    position: relative;
}
@media (max-width: 1044px) {
    .data-hud {
        position: relative;
        right: auto;
        top: auto;
    }
}
</style>


