<template>
  <div
    class="modal fade"
    id="quickTutorialModal"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-fullscreen">
      <div class="modal-content">
        <div class="modal-close-button close">
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <h1 class="modal-header fs-3">{{ t("scan_tutorial.title") }}&trade;</h1>
        <div class="modal-body">
          <div class="container">
            <!-- <b-carousel
                    id="carousel-no-animation"
                    style="text-shadow: 0px 0px 2px #000"
                    indicators
                    img-width="1024"
                    img-height="480"
                >
                    <b-carousel-slide
                    caption="First Slide"
                    img-src="https://picsum.photos/1024/480/?image=10"
                    ></b-carousel-slide>
                    <b-carousel-slide
                    caption="Second Slide"
                    img-src="https://picsum.photos/1024/480/?image=12"
                    ></b-carousel-slide>
                    <b-carousel-slide
                    caption="Third Slide"
                    img-src="https://picsum.photos/1024/480/?image=22"
                    ></b-carousel-slide>
                </b-carousel> -->
            <div class="content">
              <div class="content-title">
                {{ t("scan_tutorial.step1.title") }}
              </div>
              <figure class="figure mx-auto d-block">
                <img
                  src="./assets/inside-border.png"
                  class="figure-img mx-auto img-fluid d-block"
                />
              </figure>
              <div class="paragraph-div">
                <div class="paragraph-content w-100">
                  <div class="row">
                    <div class="col">
                      {{ t("scan_tutorial.step1.paragraph") }}
                    </div>
                  </div>
                  <br />
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <div>{{ t("scan_tutorial.dos") }}</div>
                      <ul>
                        <li>{{ t("scan_tutorial.step1.do1") }}</li>
                        <li>{{ t("scan_tutorial.step1.do2") }}</li>
                        <li>{{ t("scan_tutorial.step1.do3") }}</li>
                      </ul>
                    </div>
                    <div class="col-12 col-md-6">
                      <div>{{ t("scan_tutorial.donts") }}</div>
                      <ul>
                        <li>{{ t("scan_tutorial.step1.dont1") }}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <br />
            </div>

            <div class="content">
              <div class="content-title">
                {{ t("scan_tutorial.step2.title") }}
              </div>
              <figure>
                <div class="row">
                  <img
                    src="./assets/steady-laptop.jpg"
                    class="figure-img mx-auto img-fluid d-block col-6"
                    alt=""
                  />
                  <img
                    src="./assets/steady-phone.jpg"
                    class="figure-img mx-auto img-fluid d-block col-6"
                    alt=""
                  />
                </div>
              </figure>
              <div class="paragraph-div">
                <div class="paragraph-content w-100">
                  <div class="row">
                    <div class="col">
                    {{ t("scan_tutorial.step2.paragraph") }}
                  </div></div>
                  <br />
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <div>{{ t("scan_tutorial.dos") }}</div>
                      <ul>
                        <li>{{ t("scan_tutorial.step2.do1") }}</li>
                        <li>{{ t("scan_tutorial.step2.do2") }}</li>
                      </ul>
                    </div>
                    <div class="col-12 col-md-6">
                      <div>{{ t("scan_tutorial.donts") }}</div>
                      <ul>
                        <li>{{ t("scan_tutorial.step2.dont1") }}</li>
                        <li>{{ t("scan_tutorial.step2.dont2") }}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <br />
            </div>

            <div class="content">
              <div class="content-title">
                {{ t("scan_tutorial.step3.title") }}
              </div>
              <figure class="figure mx-auto d-block">
                <img
                  src="./assets/whole-face.png"
                  class="figure-img mx-auto img-fluid d-block"
                />
              </figure>
              <div class="paragraph-div">
                <div class="paragraph-content">
                  <div class="row">
                    <div class="col">
                    {{ t("scan_tutorial.step3.paragraph") }}
                  </div></div>
                  <br />
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <div>{{ t("scan_tutorial.dos") }}</div>
                      <ul>
                        <li>{{ t("scan_tutorial.step3.do1") }}</li>
                        <li>{{ t("scan_tutorial.step3.do2") }}</li>
                      </ul>
                    </div>
                    <div class="col-12 col-md-6">
                      <div>{{ t("scan_tutorial.donts") }}</div>
                      <ul>
                        <li>{{ t("scan_tutorial.step3.dont1") }}</li>
                        <li>{{ t("scan_tutorial.step3.dont2") }}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <br />
            </div>

            <div class="content">
              <div class="content-title">
                {{ t("scan_tutorial.step4.title") }}
              </div>
              <figure class="figure mx-auto d-block">
                <img
                  src="./assets/indoor-light.png"
                  class="figure-img mx-auto img-fluid d-block"
                />
              </figure>
              <div class="paragraph-div">
                <div class="paragraph-content">
                  <div class="row">
                    <div class="col">
                    {{ t("scan_tutorial.step4.paragraph") }}
                  </div>
                  </div>
                  <br />
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <div>{{ t("scan_tutorial.dos") }}</div>
                      <ul>
                        <li>{{ t("scan_tutorial.step4.do1") }}</li>
                      </ul>
                    </div>
                    <div class="col-12 col-md-6">
                      <div>{{ t("scan_tutorial.donts") }}</div>
                      <ul>
                        <li>{{ t("scan_tutorial.step4.dont1") }}</li>
                        <li>{{ t("scan_tutorial.step4.dont2") }}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <br />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal">
            {{ t("button.continue") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
  name: "scan-tutorial",
  props: {
    show: Boolean,
  },
  setup() {
    const { t, locale } = useI18n();
    return { t, locale };
  },
  components: {},
  methods: {},
  computed: {
    // showGuide() {
    //     console.log("show guide")
    //     return 0
    // }
  },
};
</script>

<style scoped>
.modal {
  overflow: hidden;
  max-height: 95%;
  max-width: 98%;
  left: 1%;
  top: 2.5%;
  border-radius: 10px;
}
.modal-dialog {
  width: 100%;
  height: 100%;
}
.modal-close-button {
  position: fixed;
  top: 4.5%;
  right: 2%;
}
/* Modal Content/Box */
.modal-content {
  position: grid;
  background-color: #fefefe;
  margin: 0% auto;
  padding: 5px;
  border: 1px solid #888;
  min-height: 95%;
  min-width: 100%;
  overflow: hidden;
}
.modal-header {
  display: flex;
  height: 10%;
  justify-content: center;
  font-size: xx-large;
}
.modal-body {
  display: grid;
  width: 100%;
  justify-content: center;
  padding: 2px;
  margin: 2px auto;
}
.content {
  /* border-style: dashed;
    border: 2px purple; */
  padding: 1%;
}
.content-title {
  font-weight: bolder;
  font-size: x-large;
}
.photo-div {
  /* border: 1px red;
    border-style: solid; */
  margin: 0 auto;
  padding: 10px;
}
.photo {
  max-width: 80%;
  margin: 0 auto;
}
.center-item {
  display: grid;
  justify-content: center;
}
.paragraph-div {
  /* border: 1px blue;
    border-style: solid; */
  text-align: justify;
  padding: 5px;
}
.paragraph-title {
  font-weight: bolder;
  font-size: x-large;
}
.paragraph-content {
  font-size: medium;
  width: 100%;
}
.modal-footer {
  bottom: 0px;
  height: 10%;
  justify-content: center;
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
</style>


