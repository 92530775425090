<template>
    <div id="breathing-circle-container" v-if="state == 'measuring'">
        <svg :width="radius * 3" :height="radius * 3">
            <circle class="breathing-circle-animation"
                :r="radius * 1.5"
                :cx="radius * 1.5"
                :cy="radius * 1.5"
            />
        </svg>
        <p class="text" :style="inOutTextStyle"></p>
    </div>
</template>

<script>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

export default {
    name: 'breathing-circle',
    props: {
        radius: Number,
        state: String
    },
    setup() {
   const { t, locale } = useI18n()
        const bcState = ref(false)
        /* console.log(props.radius, props.state, props.timeLeft, bcText) */
        return {t, locale, bcState, }
    },
    computed: {
        inOutTextStyle() {
            var _in = this.$t('message.breath_in');
            var _out = this.$t('message.breath_out');
            return `--in:"${_in}"; --out:"${_out}";`;
        }
    }
}
</script>

<style scoped>
#breathing-circle-container {
  position: absolute;
  left: 0px;
  width: 100%;
  bottom: 195px;
  display: flex;
  justify-content: center;
  pointer-events: none;
}
.btn-circle {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    padding: 10px 16px;
    font-size: 16px;
    text-align: center;
    opacity: .5;
}
.text {
    position: absolute;
    left: 50%;
    top: 50%;
    display: flex;
    justify-content: center;
}
@keyframes animation {
    0% {opacity: 0}
    50% {opacity: .9}
    100% {opacity: 0}
}
@keyframes textAnimation {
    0% {opacity: 1.0; font-size: 1.2em; content: var(--in);}
    45% {opacity: 1.0; font-size: 3.2em; content: var(--in);}
    46% {opacity: 1.0; font-size: 3.2em; content: var(--out);}
    90% {opacity: 1.0; font-size: 1.2em; content: var(--out);}
    91% {opacity: 1.0; font-size: 1.2em; content: var(--in);}
    100% {opacity: 1.0; font-size: 1.2em; content: var(--in);}
}
@keyframes textAnimation-mobile {
    0% {opacity: 1.0; font-size: 1em; content: var(--in);}
    45% {opacity: 1.0; font-size: 1.5em; content: var(--in);}
    46% {opacity: 1.0; font-size: 1.5em; content: var(--out);}
    90% {opacity: 1.0; font-size: 1em; content: var(--out);}
    91% {opacity: 1.0; font-size: 1em; content: var(--in);}
    100% {opacity: 1.0; font-size: 1em; content: var(--in);}
}
@keyframes circleAnimation {
    0% {opacity: .75; fill: #ffaa00; transform: scale(0.2); }
    50% {opacity: .9; fill: #00cc00; transform: scale(0.7);}
    100% {opacity: .75; fill: #ffaa00; transform: scale(0.2);}
}
@keyframes circleAnimation-mobile {
    0% {opacity: .75; fill: #ffaa00; transform: scale(0.3); }
    50% {opacity: .9; fill: #00cc00; transform: scale(0.4);}
    100% {opacity: .75; fill: #ffaa00; transform: scale(0.3);}
}
p::before {
    content: "";
    position: relative;
    left: -50%;
    transform: translate(0%, -50%);
    font-size: 1.5em;
    color: white;
    animation-name: textAnimation;
    animation-duration: 6s;
    animation-iteration-count: infinite;
    animation-delay: 1;
}
.breathing-circle-animation {
    opacity: 1;
    position: relative;
    left: 50%;
    top: 50%;
    animation-name: circleAnimation;
    animation-duration: 6s;
    animation-iteration-count: infinite;
    transform-origin: center center;
}

@media (max-width: 768px) {
    .breathing-circle-animation {
        animation-name: circleAnimation-mobile;
    }
    p::before {
        animation-name: textAnimation-mobile;
    }
    #breathing-circle-container {
        bottom: 140px;
    }
}
</style>