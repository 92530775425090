// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

let _db

export function useFirebase() {

    // Your web app's Firebase configuration
    const firebaseConfig = {
        apiKey: "AIzaSyAs5BWlRPSdoN4Aa6aSRaBiCruWIvgxla4",
        authDomain: "wvs-cloud.firebaseapp.com",
        projectId: "wvs-cloud",
        storageBucket: "wvs-cloud.appspot.com",
        messagingSenderId: "164443151996",
        appId: "1:164443151996:web:51ffe58ea6558116e133f9"
    };

    // Initialize Firebase
    initializeApp(firebaseConfig);
    _db = getFirestore()
    console.log("%c < init firebase > ", "color: #A0A0A0", new Date())

}

export {
    _db as db
}