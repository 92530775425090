<template>
  <div class="container">
    <p class="message">Just doing some final checks...</p>
    <div class="condition-list">
      <p class="condition">
        <condition-icon class="icon" :fulfilled="conditions.brightness" />
        {{t('message.conditions.lighting')}}
      </p>
      <p class="condition">
        <condition-icon class="icon" :fulfilled="conditions.distance" />
        {{t('message.conditions.distance')}}
      </p>
      <p class="condition">
        <condition-icon class="icon" :fulfilled="conditions.centered" />
        {{t('message.conditions.centered')}}
      </p>
      <p class="condition">
        <condition-icon class="icon" :fulfilled="conditions.movement" />
        {{t('message.conditions.movement')}}
      </p>
      <p class="condition">
        <condition-icon class="icon" :fulfilled="conditions.serverReady" />
        {{t('message.conditions.server_ready')}}
      </p>
      <!-- <p class="condition">
                <condition-icon class="icon" :fulfilled="conditions['face direction']" />
                Face direction
            </p> -->
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import ConditionIcon from "./condition-icon.vue";

export default {
  name: "conditions-hud",
  props: ["conditions"],
  setup() {
    const { t, locale } = useI18n();
    return {
      t,
      locale,
    };
  },
  computed: {
    isOK() {
      return Object.values(this.conditions).reduce(
        (previousValue, currentValue) => {
          return previousValue && currentValue;
        },
        true
      );
    },
  },
  components: {
    ConditionIcon,
  },
};
</script>

<style scoped>
.container {
  width: 230px;
  height: 264px;
  text-align: center;
  display: inline-block;

  background: #4643D3;
  backdrop-filter: blur(100px);
  border-radius: 10px;

  position: absolute;
  right: 25px;
  top: 275px;
}
.message {
  margin-top: 30px;
  margin-right: 35px;
  margin-left: 35px;
  font-style: normal;
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
}
.condition {
  text-align: left;
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
}
.condition-list {
  line-height: 28px;
  margin-bottom: 2em;
  margin-left: 2em;
  margin-top: 1em;
}
.icon {
  margin-left: 0em;
  margin-right: 0.5em;
}
@media (max-width: 600px) {
  .container {
    width: 180px;
  }
  .message {
    margin-right: 10px;
    margin-left: 10px;
  }
  .condition {
    font-size: 13px;
  }
}
</style>
