<template>
    <data-hud class="data-hud" title="Body Temperature" unit="°C" :text="text" :data="data"/>
</template>

<script>
import DataHud from "./data-hud.vue"

export default
  {
    name: 'temperature',
    props: {
        data: Number
    },
    data() {
        return {
            text: "Your body temperature is now 36.5°C which falls into excellent range"
        }
    },
    setup() {
    },
    components: { DataHud }
}
</script>

<style scoped>
.data-hud {
    position: relative;
    margin-left: 10px;
    left: auto;
    top: auto;
}
</style>


