<template>
  <div
    class="modal fade"
    id="userProfileInput"
    tabindex="-1"
    aria-hidden="true"
    v-show="!profileFilled"
  >
    <div class="modal-dialog ">
      <div class="modal-content">
        <div class="modal-header px-3">
          <div class="container">
            <div class="row ">
              <div class="col-2">
                  <img src="./assets/lify.svg" class="logo">
              </div>
              <div class="col">
                  <div class="row title">
                      Lify Personalized Wellness Beverages
                  </div>
                  <div class="row subtitle">
                      Recommendations tailormade just for you
                  </div>
                </div>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="row">
              <div class="question">
                Input your basic information to get an accurate scan.
              </div>
            </div>
          </div>
        </div>
        <div class="modal-body">
          <div class="container">
            <!-- title -->

            <form v-on:submit="saveSettings">
              <!-- Age -->
              <div class="row mb-3">
                <div class="col-6">
                  <label for="heightInput" class="form-label">
                    Your Age
                  </label>
                </div>
                <div class="col-6">
                  <input
                    type="number"
                    class="form-control input"
                    id="heightInput"
                    v-model="this.info.age"
                    @input="checkDisabled"
                  />
                </div>
              </div>

              <!-- Gender -->
              <div class="row mb-3">
                <div class="col-6">
                  <label for="genderInput" class="form-label">
                    Your Gender at Birth
                  </label>
                </div>
                <div class="col-6">
                  <select
                    class="form-select input"
                    id="genderInput"
                    @change="checkDisabled"
                    v-model="this.info.gender"
                  >
                    <option @change="checkDisabled" value=""></option>
                    <option @change="checkDisabled" value="male">
                      {{ t("user_info.male") }}
                    </option>
                    <option @change="checkDisabled" value="female">
                      {{ t("user_info.female") }}
                    </option>
                  </select>
                </div>
              </div>
            </form>

          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn continue-button" :disabled="disabled" data-bs-dismiss="modal" @click="updateProfile">
            Continue
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from "vue";
import { useI18n } from "vue-i18n";

export default {
  name: "user-profile-input",
  props: {
    form: Object,
  },
  setup() {
    const { t, locale } = useI18n();
    const {profileFilled, updateProfileFilled} = inject('profileFilled');
    return { 
      profileFilled,
      updateProfileFilled,
      t, locale
    };
  },
  emits: ["updateProfile"],
  mounted() {
    setTimeout(() => {
      this.checkDisabled();
    }, 300);
  },
  components: {},
  methods: {
    loadForm() {
        this.info = this.form;
        if (this.debug) {
          this.info = {
            name: "DebugUser",
            email: "debug@panoptic.ai", 
            phoneNumber: "12345678",
            birthday: "2019-08-19",
            gender: "male",
            height: 170,
            weight: 70,
            unitSystem: false,
            countries: "Hong Kong",
            smoker: false,
            hypertension: false,
            bloodPressureMedication: false,
            diabetic: "No",
            heartDisease: false,
            depression: false,
          }
        }
    },
    checkDisabled() {
      let check = false;
      Object.keys(this.info).map((data) => {
        if (this.info[data] === null || this.info[data] === "") {
          check = true;
          return;
        }
      });
      this.disabled = check;
    },
    updateProfile() {
      this.$emit("updateProfile", this.info);
    },
  },
  watch: {
    form(newValue) {
        this.info = newValue;
        this.checkDisabled();
    }
  },
  data() {
    return {
      info: {
        gender: "",
        age:""
      },
      disabled: true, 
      debug: this.$root.debugMode
    };
  },
};
</script>

<style scoped>
.modal {
  overflow: hidden;
  border-radius: 10px;
  opacity: 0.85;
}
.modal-dialog {
  display: flex;
  max-width: 611px;
  width: 100%;
  height: 100%;
  align-items: center;
  margin: 0 auto;
}
.modal-close-button {
  position: fixed;
  top: 4.5%;
  right: 3.5%;
}
/* Modal Content/Box */
.modal-content {
  position: grid;
  background-color: #fefefe;
  margin: 0% auto;
  padding: 5px;
  border: 1px solid #888;
  min-width: 100%;
  overflow: hidden;
  border-radius: 24px;
  padding: 0px;
}
.modal-header {
  display: flex;
  height: 10%;
  justify-content: center;
  font-size: xx-large;
  border: none;
  color: white;
  background: #4643D3;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  padding-top: 25px;
}
.modal-body {
  justify-content: center;
  padding: 2px;
  margin: 10px;
}
.form-label {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #5856D7;
  margin: 7px 0px;
}
.continue-button {
  background-color: #FE805C;
  color: white;
  font-size: 14px;
  font-family: Montserrat;
  border-radius: 24px;
  height: 40px;
}
.btn-close {
  top: 0%;
}
.content {
  /* border-style: dashed;
    border: 2px purple; */
  padding: 1%;
}
.content-title {
  font-weight: bolder;
  font-size: x-large;
}
.photo-div {
  /* border: 1px red;
    border-style: solid; */
  margin: 0 auto;
  padding: 10px;
}
.photo {
  max-width: 80%;
  margin: 0 auto;
}
.center-item {
  display: grid;
  justify-content: center;
}
.paragraph-div {
  /* border: 1px blue;
    border-style: solid; */
  text-align: justify;
  padding: 5px;
}
.paragraph-title {
  font-weight: bolder;
  font-size: x-large;
}
.paragraph-content {
  font-size: medium;
  width: 100%;
}
.modal-footer {
  bottom: 0px;
  height: 10%;
  justify-content: center;
  border: none;
  padding-top: 0;
}
.logo {
  width: 48px;
  border-radius: 50%;
  border: 1px solid #FFFFFF;
}
.title {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.25px;
    text-align: left;
    color: white;
}
.subtitle {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: white;
    opacity: 0.5;
}
.question {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: white;
}
.input {
  border: 1px solid #FE805C;
  border-radius: 24px;
  text-align: center;
  text-align: -moz-center;
  text-align: -webkit-center;
  font-weight: 700;
}
/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
select {    
    padding-right: 41.5%;
    direction: rtl;
}
@media (max-width: 600px) {
  .modal-dialog {
    max-width: 450px;
  }
  .modal-body {
    margin: 10px 10px 0px 10px;
  }
  .form-label {
    font-size: 15px;
  }
  .continue-button {
    font-size: 12px;
  }
  .title {
    font-size: 16px;
  }
  .subtitle {
    font-size: 12px;
  }
  .question {
    font-size: 16px;
  }
  .form-select {
    text-align: center;
  }
  select {
    padding-left: 0;
    padding-right: 0;
    direction: ltr2222;
  }
}
</style>


