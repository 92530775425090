import type { Health, companyUser, userSettings, VitalSignOld, companySettings, scanResults, User } from "./user-definitions";
import { BaseCompanyUser, BaseUser } from "./user-base";
import {
    generateEmptyCompanySettings,
  generateEmptyUserSettingsObject,
  generateId,
  constructVitalSignsFromHealth,
} from "./user_utilities";
import { maskOffEmail } from "./privacy-utilities"
import { cloudBackend } from "./user-manager-definitions";
import { Auth, Hub } from "aws-amplify";
import DynamoDB from "../backend/dynamodb-client";
import { marshall } from "@aws-sdk/util-dynamodb";
import _ from "lodash";
import Logger from "@/common/logger";


export class AwsUser extends BaseCompanyUser implements companyUser {
    declare UID: string | undefined;
    declare companySettings: companySettings | undefined;
    declare currentUser: BaseUser | undefined;
    declare scanResults: scanResults | undefined;
    cloudInfo: any
    userType: cloudBackend = cloudBackend.aws;

    userId: string = "aws_user_123";

    constructor() {
        super();
    }
    
    async getCompanySettings(): Promise<companySettings | undefined> {
        // Load the user settings
        // Logger.log('call getUsersettings for uid', this.UID, this.cloudInfo)
        try {
            const params = {
                TableName: "companyProfile",
                ReturnConsumedCapacity: "TOTAL",
                Key: { UID: { S: this.UID } },
            };
            const results = await DynamoDB.readDataFromDb(params);
            Logger.log(`%c< query DynamoDb > companyUser.getCompanySettings @ UID=${this.UID}`, "color: #A0A0A0", );
            if (results) {
                this.companySettings = <companySettings>results;
            } else {
                this.companySettings = generateEmptyCompanySettings();
            }
            if ( this.UID ) { this.companySettings.UID = this.UID; }
        } catch(e) {
            console.error(e)
        }
        return this.companySettings;
    }
    async setCompanySettings(newCompanySettings: companySettings): Promise<any> {
        try {
            Logger.log("%csetCompanySettings: ", "color: #A0A0A0", this.companySettings, newCompanySettings)
            this.companySettings = newCompanySettings;
      
            const params = {
              TableName: "companyProfile",
              ReturnConsumedCapacity: "TOTAL",
              Item: marshall(this.companySettings),
            };
            await DynamoDB.updateItemInDb(params);
            Logger.log(`%c < updateItem DynamoDb > companyUser.setCompanySettings`, "color: #696969")
            return true;
          } catch (err) {
            console.error("%cFailed to update settings", err);
            return false;
          }
    }
    changePassword(oldPassword: string, newPassword: string): Promise<boolean> {
        throw new Error("Method not implemented.");
    }
    async login(): Promise<void> {
        let user = await Auth.currentUserInfo();
        if (user) {
            this.cloudInfo = user;
            this.UID = this.cloudInfo.username;  // update UID after login
        }
        else {
            return new Promise((resolve) => {
            Hub.listen("auth", (data) => {
                const { payload } = data;

                switch (payload.event) {
                case "signIn":
                    Logger.info("user signed in");
                    this.userHasLoggedIn(resolve);
                    break;
                case "signUp":
                    Logger.info("user signed up");
                    this.userHasLoggedIn(resolve);
                    break;
                }
            });
            });
        }
    }
    async logout(): Promise<void> {
    try {
        await Auth.signOut({ global: true });
    } catch (error) {
        console.error("error signing out: ", error);
    }
    }
    async ready(): Promise<void> {
        let user = await Auth.currentUserInfo();
        if (user) {
            this.cloudInfo = user;
        }
    }
    async uploadScanResults(): Promise<void> {
        this.constructScanResults();

        const docId = [
            this.UID?.toString(),
            new Date().toISOString(),
            generateId(4),
        ].join("_");

        let objToSave = {
            id: docId,
            ...this.scanResults,
        };

        // Config DynamoDB and run query
        const marshallOptions = {
            convertEmptyValues: true, // false, by default.
            removeUndefinedValues: false, // false, by default.
            convertClassInstanceToMap: true, // false, by default.
        };

        const params = {
            TableName: "companyData",
            ReturnConsumedCapacity: "TOTAL",
            Item: marshall(objToSave, marshallOptions),
        };

        await DynamoDB.updateItemInDb(params);
        Logger.log("%cuploadScanResults", "color: #696969", this.scanResults)
    }

  async userHasLoggedIn(resolve: Function) {
    this.cloudInfo = await Auth.currentUserInfo();
    this.UID = this.cloudInfo.username;  // update UID after login
    resolve();
  }

  // Should be connected to db
  async loadData(): Promise<void> {
    // Set user ID
    // this.userId = this.cloudInfo.username;

    // let month = new Date();
    // let n = 0;
    // month.setDate(month.getDate() - 30 - n);
    // month.setHours(0, 0, 0, 0);
    // let week = new Date();
    // week.setDate(week.getDate() - 7 - n);
    // week.setHours(0, 0, 0, 0);
    // let day = new Date();
    // day.setDate(day.getDate() - n);
    // day.setHours(0, 0, 0, 0);
    // let now = new Date();
    // now.setDate(now.getDate() - n);

    // const params = {
    //   TableName: "userData",
    //   ReturnConsumedCapacity: "TOTAL",
    //   IndexName: "datetimeIndex",
    //   KeyConditionExpression:
    //     "userId = :v1 AND #datetime BETWEEN :v2a AND :v2b",
    //   ExpressionAttributeNames: { "#datetime": "datetime" },
    //   ExpressionAttributeValues: {
    //     ":v1": { S: this.userId },
    //     ":v2a": { S: month.toISOString() },
    //     ":v2b": { S: now.toISOString() },
    //   },
    // };

    // let array = await DynamoDB.queryDb(params);
    // const constructData = (
    //   noOfDays: number,
    //   temp: any,
    //   date: Date,
    //   fromDate: Date
    // ) => {
    //   let res = Array(noOfDays).fill({});
    //   temp.forEach((elem: any) => {
    //     let time = new Date(elem.datetime);
    //     if (time > fromDate) {
    //       let dayDiff: any =
    //         (date.getTime() - time.getTime()) / (1000 * 3600 * 24);
    //       dayDiff = parseFloat(dayDiff).toFixed(0);
    //       let vitalSigns = constructVitalSignsFromHealth(elem);
    //       res[noOfDays - 1 - dayDiff] = vitalSigns;
    //     }
    //   });
    //   return res;
    // };
    // const constructOneDayData = (temp: any, fromDate: Date) => {
    //   let res: any[] = [];
    //   temp.forEach((elem: any) => {
    //     let vitalSigns = constructVitalSignsFromHealth(elem);
    //     let time = new Date(elem.datetime);
    //     if (time > fromDate) {
    //       res.push({
    //         datetime: time,
    //         vitalSigns: vitalSigns,
    //       });
    //     }
    //   });
    //   return res;
    // };

    // if (array) {
    //   let castedArray: Array<Health> = array.map((item) => item as Health);
    //   this.oneMonthData = constructData(30, castedArray, now, month);
    //   this.oneWeekData = constructData(7, castedArray, now, week);
    //   this.oneDayData = constructOneDayData(castedArray, day);
    //   this.data = castedArray;
    //   let lastHealthData = Object.assign({}, castedArray.slice(-1)[0]);
    //   if (lastHealthData) {
    //     this.lastHealth = lastHealthData;
    //     if (this.lastHealth && lastHealthData.datetime) {
    //       this.lastHealth.datetime = new Date(lastHealthData.datetime);
    //     }
    //     this.lastVitalSigns = constructVitalSignsFromHealth(lastHealthData);
    //   }
    // }

    Logger.log("%cTODO: AWS loadData", "color: red");
  }

  async uploadFeedback(): Promise<void> {
    let now = new Date();
    const docId = [
        this.UID?.toString(),
        now.toISOString(),
        generateId(4),
    ].join("_");
    if (this.currentUser && this.currentUser.rating) {
        
        let age = this.currentUser.userSettings?.birthday ? this.currentUser.userSettings?.birthday : null;
        if (age) {
            age = `${now.getFullYear() - parseInt( age.split('-')[0] )}`;
        }
        let feedback = {
            id: docId,
            datetime: now.toISOString(),
            UID: this.UID,
            email: maskOffEmail(this.currentUser.userSettings?.email),
            age: age ? parseInt(age) : null,
            gender: this.currentUser.userSettings?.gender,
            rating: parseInt(this.currentUser.rating),
            comments: null,
        }
        // Config DynamoDB and run query
        const marshallOptions = {
            convertEmptyValues: true, // false, by default.
            removeUndefinedValues: false, // false, by default.
            convertClassInstanceToMap: true, // false, by default.
        };

        const params = {
            TableName: "companyFeedback",
            ReturnConsumedCapacity: "TOTAL",
            Item: marshall(feedback, marshallOptions),
        };

        await DynamoDB.updateItemInDb(params);
        Logger.log("%cuploadFeedback: ", "color:#696969", feedback, )
    }
  }
  async uploadSubscriberEmail(email: String): Promise<Boolean> {
    let now = new Date();
    const docId = [
        this.UID?.toString(),
        now.toISOString(),
        generateId(4),
    ].join("_");
    if (this.currentUser) {
        
        let age = this.currentUser.userSettings?.birthday ? this.currentUser.userSettings?.birthday : null;
        if (age) {
            age = `${now.getFullYear() - parseInt( age.split('-')[0] )}`;
        }
        let payload = {
            id: docId,
            UID: this.UID,
            email: email,
        }
        // Config DynamoDB and run query
        const marshallOptions = {
            convertEmptyValues: true, // false, by default.
            removeUndefinedValues: false, // false, by default.
            convertClassInstanceToMap: true, // false, by default.
        };

        const params = {
            TableName: "subscriber",
            ReturnConsumedCapacity: "TOTAL",
            Item: marshall(payload, marshallOptions),
        };

        const res = await DynamoDB.updateItemInDb(params);
        if (res) {
            Logger.log("%cuploadSubscriberEmail: ", "color:#696969", payload, )
            return true;
        }
    }
    return false;
  }
}
