
export function maskOffName(name) {
    let result = name;
    console.log(`maskOffName: ${name} -> ${result}`);

    return result;
}

export function maskOffEmail(email) {
    if (!email) {
        return null
    }
    
    let result = email;
    if (email.toString().search("@") > 0) {
        let emailSplitted = email.toString().split("@");
        let address = emailSplitted[0];
        let domain = emailSplitted[1];
        let maskedAddress;
        let maskedDomain;
        if ( address.length > 7 ) {
            maskedAddress = [ address.substring(0, 2), "***", address.substring(address.length - 2, address.length) ].join("");
        } else {
            maskedAddress = [ address.substring(0, 1), "***", address.substring(address.length - 1, address.length) ].join("");
        }
        if (domain.length > 7 ) {
            maskedDomain = [ domain.substring(0, 2), "***", domain.substring(domain.length - 2, domain.length) ].join("");
        } else {
            maskedDomain = [ domain.substring(0, 1), "***", domain.substring(domain.length - 1, domain.length) ].join("");
        }
        result = [ maskedAddress, "@", maskedDomain ].join("");
    }
    return result;
}

export function maskOffBirthday(birthday) {
    let result = birthday;
    console.log(`maskOffBirthday: ${birthday} -> ${result}`);

    return result
}

export function maskOffPhoneNumber(phoneNumber) {
    let result = phoneNumber;
    console.log(`maskOffPhoneNumber: ${phoneNumber} -> ${result}`);

    return result 
}