<template>
    <div :class="`card-container justify-content-center ${content.options.length > 1 ? ' big' : ' small'}`">
        <div class="header w-100 m-0 ">
            <div class="row">
                <div :class="col">
                    <img v-if="content.image" :src="require('' + content.image)" class="logo-image">
                    <div v-else class="row align-items-center logo">
                        <p class="m-0 p-0">
                        {{number}}/5
                        </p>
                    </div>
                </div>
                <div class="col">
                    <div class="row title">
                        {{title}}
                    </div>
                    <div class="row subtitle">
                        {{subtitle}}
                    </div>
                </div>
            </div> 
            <div class="row ">
                <div class="question">
                    {{content.question}}
                </div>
            </div>
        </div>
        <div class="row w-100 m-0 justify-content-center"  v-if="content.options.length > 1">
            <div :class="`${content.options.length%2 == 0 ? 'col-6' : 'col-4'} p-3 option-container`" v-for="(option, index) in content.options" :key="index">
                <div class="option" @click="nextQuestion(); addResult(index)">
                    {{option}}
                </div>
            </div>
        </div>
        <div v-else-if="!last" class="content row align-items-center p-0 next-button" @click="nextQuestion">
            <p class="text-center next-text">{{content.options[0]}}</p>
        </div>
        <div v-else 
            class="content row align-items-center p-0 next-button" 
            data-bs-toggle="modal"
            data-bs-target="#result"
            @click="nextQuestion">
            <p class="text-center next-text">{{content.options[0]}}</p>
        </div>
    </div>
</template>

<script>
export default {
    setup() {
        
    },
    props: {
        title: String,
        subtitle: String,
        content: Object,
        number: Number,
        last: Boolean
    },
    emits: ['nextQuestion', 'addResult'],
    methods: {
        nextQuestion() {
            this.$emit('nextQuestion');
        },
        addResult(number) {
            this.$emit('addResult', this.content.result[number]);
        }
    },
    computed: {
        col() {
            if (window.innerWidth < 834) {
                return "col-2";
            } else {
                if (this.content.options.length > 1) {
                    return "col-1";
                } else {
                    return "col-2";
                }
            }
        }
    }
}
</script>

<style scoped>
.card-container {
    border-radius: 24px;
    position: absolute;
    margin: 0;
    padding: 0;
    background-color: white;
}
.header {
    background-color: #4643D3;
    border-radius: 24px 24px 0px 0px;
    padding: 25px
}
.title {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.25px;
    text-align: left;
    color: white;
}
.subtitle {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: white;
    opacity: 0.5;
}
.question {
    padding-top: 10px;
    padding-left: 20px;
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: white;
}
.option {
    background-color: #FE805C;
    border-radius: 24px;
    text-align: center;
    color: white;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0.25px;
    text-align: center;
    padding: 10px;
    cursor: pointer;
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.logo {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.25px;
    text-align: center;
    width: 48px;
    height: 48px;
    color: white;
    background-color: #FE805C;
    border-radius: 50%;
    margin: 0px;
    padding: 0;
}

.logo-image {
  width: 48px;
  border-radius: 50%;
  border: 1px solid #FFFFFF;
}
.next-text {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    margin: 5px 0;
    color: #5856D7;
}
.small {
    width: 500px;
    background-color: white;
}
.big {
    width: 982px;
}
.content {
    height: 58px;
}
.next-button {
    cursor: pointer;
}
.option-container {
    position: relative;
}
@media (max-width: 1024px) {
    .header {
        margin: 25px 10px;
    }
    .header > .row > .col-1 {
        padding: 0 3px;
    }
    .small {
        width: 611px;
    }
}

@media (max-width: 834px) {
    .big {
        width: 770px;
    }
}

@media (max-width: 600px) {
    .header {
        padding: 20px;
    }

    .title {
        font-size: 16px;
    }
    
    .subtitle {
        font-size: 12px;
    }

    .question {
        font-size: 16px;
    }

    .option {
        font-size: 12px;
    }

    .next-text {
        font-size: 16px;
    }

    .small {
        width: 420px;
    }

    .content {
        height: 45px;
    }

    .big {
        width: 550px;
    }
}
</style>