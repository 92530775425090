<template>
  <div class="hud-container">
    <div class="container">
      <div class="row w-100 m-0 align-items-end justify-content-center">
        <div class="data">{{ value }}</div>
      </div>
      <div class="row unit w-100 m-0">
        <div class="unit">{{ unit }}</div>
      </div>
      <!-- <div class="unit">
            <p class="text-right">{{unit}}</p>
        </div> -->
      <div class="row">
        <p class="title">{{ title }}</p>
      </div>
      <div class="row w-100 justify-content-center m-0 description">
        {{ text }}
      </div>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
  name: "data-hud",
  props: {
    data: {},
    title: { type: String, default: "Heart Rate" },
    unit: { type: String, default: "bpm" },
    text: { type: String, default: "" },
  },
  setup() {
    const { t, locale } = useI18n();
    return {
      t,
      locale,
    };
  },
  computed: {
    value() {
      if(this.title == "Stress Rating") {
        return `${this.data.toFixed(0)} / 5`;
      }
      if (typeof this.data == "number") {
        return this.data.toFixed(0);
      } else if (typeof this.data == "string") {
        return this.data;
      } else {
        return "...";
      }
    },
    isComingSoon() {
      return (
        typeof this.data === "string" &&
        this.data.toLowerCase() === "coming soon"
      );
    },
  },
};
</script>



<style scoped>
.hud-container {
  width: 242px;
  height: 278px;
  text-align: center;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  background: white;
  backdrop-filter: blur(100px);
  box-shadow: 0.926754px 9.26754px 9.26754px rgba(102, 62, 51, 0.02),
    9.26754px 18.5351px 18.5351px rgba(102, 62, 51, 0.04),
    18.5351px 27.8026px 27.8026px rgba(102, 62, 51, 0.06),
    50.9715px 60.239px 40.7772px rgba(197, 104, 78, 0.13);
  border-radius: 22.2421px;
}
.title {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  color: black;
  margin-bottom: 5px;
}
.data {
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 59px;
  text-align: center;

  color: black;
}
.unit {
  font-family: "Montserrat", sans-serif;
  margin-top: -50px;
  padding-right: auto;
  padding: 0 7px;
  font-size: 20px;
  font-weight: 300;
  text-align: right;
  color: black;
}
.description {
  font-family: "Montserrat", sans-serif;
  font-size: 11px;
  padding: 0 20px;
  color: #AFAFBD;
}
@media (max-width: 600px) {
  .hud-container {
    width: 160px;
    height: 310px;
  }
  .title {
    font-size: 16px;
  }
  .data {
    font-size: 35px;
  }
  .unit {
    font-size: 14px;
    margin-top: -30px;
  }
  .description {
    font-size: 10px;
  }
}
</style>