<template>
  <div>
    <!-- button navigation -->
    <div class="buttons">
      <div :class="`switch-button ${starting? 'white' : 'black'}`">
        <input class="switch-button-checkbox" type="checkbox" v-model="starting"/>
        <label class="switch-button-label" for=""><span class="switch-button-label-span">OFF</span></label>
      </div>
    </div>

    <!-- popup user profile -->
    <user-profile-input v-if="form" @updateProfile="updateProfile" :profileFilled="profileFilled" :form="form"/>

    <!-- video container -->
    <div id="video-container" ref="videoContainer">
      <!-- condition checking & instructions -->
      <div>
        <face-detection-view
          :enable="starting"
          :vsStatus="vsStatus"
          @finishMeasurement="finishMeasure"
          @updateTimeLeft="updateTimeLeft"
          @detectionResult="updateDetectionResult"
          @scanParameters="updateScanParameters"
          @startQuiz="startQuiz"
          :device="devices.length > 1 ? devices[cameraIndex].id : ''"
        />
      </div>

      <img id="lify-logo" src="./assets/lify.svg" />
      <img id="panoptic-logo" src="./assets/panoptic_ai.svg" />
      <!-- signal quality box -->
      <signal-quality-box :scanParameters="scanParameters" />

    <div class="huds-container" v-if="vitalSign">

      <div class="row gx-0 mb-5 justify-content-between vitals-row">
        <heart-rate :data="vitalSign.heartRate" class="col-2" />
        <o2-saturation :data="vitalSign.spo2" class="col-2" />
      </div>
      <div class="row gx-0 my-5 justify-content-between vitals-row">
        <respiratory-rate
          :data="vitalSign.respiratoryRate"
          :class="[userType == 'free' ? 'disableBox ' : '', 'col-2']"
        />
        <facial-skin-age :data="vitalSign.facialSkinAge" class="col-2" />
      </div>
      <div class="row gx-0 my-5 justify-content-between vitals-row">
        <stress-rating
          :data="vitalSign.stress"
          :class="[userType == 'free' ? 'disableBox ' : '', 'col-2']"
        />
        <wellness
          :data="vitalSign.generalWellness"
          :class="[userType == 'free' ? 'disableBox ' : '', 'col-2']"
        />
      </div>
    </div>

      <!-- conditions -->
      <div
        v-if="
          vsStatus != 'measuring' &&
            conditions &&
            sysStatus == 'running' &&
            !stopping
        "
      >
        <conditions-hud :conditions="conditions" />
      </div>
      <!-- Vital Sign Button Row -->
      <div v-if="starting" class="control-buttons">
          <vital-sign-button class="vsButton info-bar"
            :radius="radius"
            :enable="detectionResult"
            :timeLeft="getTimeLeft()"
            @vsMeasure:start="startMeasurement"
            @removeProfile="removeProfile"
          />
          <img 
            v-if="devices && devices.length > 1 && vsStatus !== 'measuring'"
            :disabled="vitalSign !== undefined" 
            class="btn camera-switch" 
            @click="switchCamera" 
            src="./assets/switch-camera.svg">
      </div>
    <quiz v-if="showQuiz" :scanFinished="scanFinished" :number="number" @displayResult="displayResult" @nextCard="nextCard"/>
    <result :showResult="showResult" :result="result" @removeProfile="removeProfile"/>
  </div>
  </div>
</template>

<script>
import Logger from "../../common/logger.js";
import { ref, inject } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import api from "../../common/api.js";
import adapter from "webrtc-adapter";
import ConditionsHud from "./conditions-hud.vue";
import FaceDetectionView from "./face-detection-view.vue";
// import SideBar from "../sidebar/sidebar.vue";
// import ScanTutorial from "./scan-tutorial.vue";
import SignalQualityBox from "./signal-quality-box-floating.vue"
import userProfileInput from "./user-profile-input.vue"
import { newUserManager } from "../../user/company-user-manager.ts";
import VitalSignButton from "./vital-sign-button-js.vue";
// vital signs 
import HeartRate from "./vital-signs/heart-rate.vue";
import RespiratoryRate from "./vital-signs/respiratory-rate.vue";
import O2Saturation from "./vital-signs/o2-saturation.vue";
import StressRating from "./vital-signs/stress-rating.vue";
import FacialSkinAge from "./vital-signs/facial-skin-age.vue";
// import HrvSdnn from "./vital-signs/hrv-sdnn.vue";
import Wellness from "./vital-signs/wellness.vue";
// import Temperature from './vital-signs/temperature.vue';
import Quiz from "./quiz.vue";
import Result from "./result.vue"
// import BloodPressure from "./vital-signs/blood-pressure.vue";
/*
import HrvIbi from "./vital-signs/hrv-ibi.vue";
import CVDRisk from './vital-signs/cvd-risk.vue';
import CovidRisk from './vital-signs/covid-risk.vue';
import bmi from './vital-signs/bmi.vue';
import HrvRmssd from './vital-signs/hrv-rmssd.vue'
*/
// import WebCam from './web-cam.vue';
// import VideoFeed from './video-feed.vue'
// import io from "socket.io-client";  // for python backend

export default {
  name: "App",
  props: {
    simulation: Boolean,
  },
  setup() {
    const { idle, updateIdle } = inject("idle");
    const { profileFilled, updateProfileFilled } = inject("profileFilled");
    const { t, locale } = useI18n();
    const router = useRouter();

    var currentUser = newUserManager.currentUser ? newUserManager.currentUser.currentUser : null;
    let vitalSign = null;
    let scanParameters = null;
    if (currentUser) {
      vitalSign = currentUser.lastVitalSigns;
      scanParameters = currentUser.lastHealth ? currentUser.lastHealth.scanParameters : null;
    } else {
      router.push("/");
    }

    return {
      t,
      locale,
      router,
      starting: ref(idle),
      stopping: ref(true),
      sysStatus: ref("standby"),
      vsStatus: ref("idle"),
      flask_fps: ref(),
      vitalSign: ref(vitalSign),
      scanParameters: ref(scanParameters),
      conditions: ref(),
      message: ref(),
      disclaimer: ref(),
      pythonBackend: ref(false),
      timeLeft: ref(null),
      user: ref(currentUser),
      idle,
      updateIdle,
      showTutorial: ref(false),
      profileFilled,
      updateProfileFilled,
    };
  },
  mounted() {
    // console.log(newUserManager.currentUser)
    // console.log(newUserManager.currentUser.currentUser)
    // console.log(newUserManager.currentUser.currentUser.getUserSettings())
    // load user / customer form

    setTimeout(async () => {
      let currentUser = newUserManager.currentUser.currentUser
      if (currentUser) {
        this.form = await currentUser.getUserSettings();
      }
    }, 50);
  }, 
  async created() {
    await this.getUserType();
    if (window.sessionStorage.userSettings) {
      this.form = JSON.parse(window.sessionStorage.userSettings);
    }
  }, 
  watch: {
    vsStatus(newValue) {
      if (newValue == "measuring") {
        this.conditions = undefined;
        this.message = undefined;
      }
    },
    devices() {
      if (this.devices[0].text !== '') {
        clearInterval(this.checkDevice);
      }
    },
    sysStatus(status) {
      Logger.log(status);
      Logger.info(`Status changed from "${this.sysStatus}" to "${status}".`);
      if (status == "running") {
        this.starting = false;
        this.message = undefined;
      }

      if (status == "standby") {
        this.stopping = false;
        this.message = undefined;
      }
    },
    healthObject: {
        deep: true,
        handler: function() {
            this.vitalSign = newUserManager.currentUser.currentUser.lastVitalSigns;
        }
    },
    async starting(val) {
      if(val) {
        this.checkDevice = setInterval(async () => {
          let devices = await navigator.mediaDevices.enumerateDevices();
          devices = devices.filter(device => device.kind === 'videoinput');
          this.devices = devices.map((device, index) => {
            return {text: device.label, id: device.deviceId, deviceIndex: index}
          })
        }, 1000);

      } else if(!val) {
        this.stopSystem();
      }
    }
  },
  data() {
    return {
      detectionResult: undefined,
      aspectRatio: undefined,
      form: undefined,
      userType: undefined,
      showResult: false,
      result: undefined,
      showQuiz: false,
      scanFinished: false,
      number: 0,
      cameraIndex: 0,
      devices: [{text: '', id: '', deviceIndex: 0}],
      checkDevice: undefined,
    };
  },
  methods: {
    switchCamera() {
      this.cameraIndex = (this.cameraIndex + 1) % this.devices.length;
    },
    async getUserType() {
      setTimeout(() => {       
        if (!this.currentUser) return;
        else {
          this.userType = this.currentUser.userSettings.planType;
        }
      }, 2000);
    },
    showGuide() {
      this.showTutorial = !this.showTutorial;
    },
    startMeasurement() {
      // check conditions
      this.vitalSign = null;
      this.scanParameters = null;
      if ( newUserManager.currentUser ) {
        let currentUser = newUserManager.currentUser.currentUser
        if ( currentUser ) { currentUser.clearHealth(); }
      }
      this.vsStatus = "measuring";
      if(newUserManager.currentUser.currentUser.quizAnswer) {
        this.number = newUserManager.currentUser.currentUser.quizAnswer.length + 1;
      }
        this.scanFinished = false;
    },
    updateTimeLeft(newValue) {
      this.timeLeft = newValue;
      // console.log("home-page time left: ", this.timeLeft);
    },
    getTimeLeft() {
      return this.timeLeft;
    },
    finishMeasure() {
      this.vsStatus = "idle";
      this.scanFinished = true;
    },
    updateDetectionResult(newValue) {
      this.detectionResult = newValue;
    },
    updateScanParameters(newValue) {
      this.scanParameters = newValue;
    },
    getAspectRatio(ar) {
      this.aspectRatio = ar;
    },
    onResult(result) {
      this.detectionResult = result;
    },
    refreshStatus() {
      api.refreshStatus().then((data) => {
        this.flask_fps = data.fps;

        if (data.status == "running") {
          this.starting = false;
        }

        if (data.status == "standby") {
          this.stopping = false;
        }

        if (this.sysStatus !== data.status) {
          Logger.info(
            `Status changed from "${this.sysStatus}" to "${data.status}".`
          );
        }

        this.sysStatus = data.status;
      });
    },
    startSystem: function() {
      Logger.info("Start button pressed");

      // this.enableSound();

      this.updateIdle();
      this.starting = true;
      this.stopping = false;
      this.conditions = undefined;
      this.vitalSign = undefined;

      if (this.pythonBackend) {
        api
          .startSystem()
          .then(() => {
            this.refreshStatus();
          })
          .catch((err) => {
            console.error(err);
            alert(err.message);
            this.starting = false;
          });
        return false;
      } else {
        //this.sysStatus = "running";
        return false;
      }
    },
    stopSystem: function() {
      Logger.info("Stop button pressed.");

      this.updateIdle();
      this.stopping = true;
      this.starting = false;
      this.vitalSign = undefined;
      this.conditions = undefined;
      this.detectionResult = undefined;
      this.vsStatus = 'idle';
      this.showQuiz = false;

      if (this.pythonBackend) {
        api.stopSystem().then(() => {
          this.starting = false;
          this.stopping = true;
          this.refreshStatus();
        });
        return false;
      } else {
        this.sysStatus = "standby";
        return false;
      }
    },
    fullScreen() {
      this.$refs.videoContainer.requestFullscreen();
    },
    showHealthReport() {
      this.$router.push('/email')
    },
    async removeProfile() {
      await newUserManager.currentUser.currentUser.clearUserSettings();
      newUserManager.currentUser.currentUser.clearQuizAnswers();
      this.form = newUserManager.currentUser.currentUser.userSettings;
      this.updateProfileFilled(false);
      this.vitalSign = null;
      this.scanParameters = null;
      this.number = 0;
      this.showQuiz = false;
      this.scanFinished = false;
    },
    async updateProfile(form) {
      await newUserManager.currentUser.currentUser.setUserSettings(form)
      this.form = newUserManager.currentUser.currentUser.userSettings;
      this.updateProfileFilled(true);
      Logger.log("updateProfile", "", this.form)
    },
    displayResult() {
      const result = newUserManager.currentUser.currentUser.quizAnswer;
      this.result = result.flat();
      this.addResult();
      if(this.result.length == 0) {
        this.result = ['stress-relief']
      }
      this.showResult = true;
      this.number -= 1;
    },
    startQuiz() {
      this.showQuiz = true;
    },
    nextCard() {
      this.number += 1;
    },
    addResult() {
      if(this.vitalSign.heartRate > 100) {
        this.result.push('stress-relief');
      }
      if(this.vitalSign.stress > 3) {
        this.result.push('stress-relief');
      }
      if(this.vitalSign.generalWellness < 40) {
        this.result.push('stress-relief');
      }
      if(this.vitalSign.facialSkinAge > 35) {
        this.result.push('detox')
      }
    }
  },
  computed: {
    healthObject() {
        if (this.$root.$data.healthObject !== undefined) {
            return "healthObject updated."
        } else {
            return "no health result"
        }
    },
    radius() {
      if (window.innerWidth < 834) {
        return 48
      }
      else {
        return 63.5
      }
    },
    gap() {
      Logger.log(`%ccurrent Browswer: [${adapter.browserDetails.browser}]`, "color: #DDDDDD");
      if (adapter.browserDetails.browser == "safari") {
        return "20px";
      } else {
        return 0;
      }
    },
  },
  components: {
    // VideoFeed,
    VitalSignButton,
    HeartRate,
    RespiratoryRate,
    O2Saturation,
    StressRating,
    // HrvIbi,
    FacialSkinAge,
    // HrvSdnn,
    // BloodPressure,
    //CVDRisk,
    //CovidRisk,
    Wellness,
    ConditionsHud,
    // SideBar,
    FaceDetectionView,
    //WebCam,
    // HomePageOnboard,
    // ScanTutorial,
    SignalQualityBox,
    userProfileInput,
    // Temperature,
    Quiz,
    Result
},
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');
html {
  scroll-behavior: smooth;
}
body {
  min-height: 98vh !important;
  background-color: lightgray;
  overflow: hidden;
  max-height: 98vh !important;
}
</style>

<style scoped>

.camera-switch {
  transform: scale(0.8);
  position: absolute;
  bottom: 4%;
  right: 3%;
}

.switch-button {
  background: rgba(255, 255, 255, 0.56);
  border-radius: 30px;
  overflow: hidden;
  width: 234px;
  height: 47px;
  text-align: center;
  font-size: 18px;
  letter-spacing: 1px;
  color: black;
  position: relative;
  padding-left: 120px;
  position: relative;
}
.switch-button:before {
  content: "ON";
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 600;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 117px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  pointer-events: none;
}

.white {
  color: white;
}

.black {
  color: black;
}

.switch-button-checkbox {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 2;
}
.switch-button-checkbox:checked + .switch-button-label:before {
  transform: translateX(-120px);
  transition: transform 300ms linear;
  background-color: #4643D3;
}

.switch-button-checkbox + .switch-button-label {
  position: relative;
  padding: 10px 0;
  display: block;
  user-select: none;
  pointer-events: none;
}

.switch-button-label:before {
  content: "";
  background: white;
  height: 47px;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  color:black;
  border-radius: 28.5px;
  transform: translateX(0);
  transition: transform 300ms;
  
}

.switch-button-label-span {
  position: relative;
  color: black;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 600;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.quick-help-button {
    /* display: inline-block; */
    opacity: 0.7;
    min-width: 40px;
    min-height: 40px;
    vertical-align: middle;
    z-index: 2;
}
.buttons {
  display: flex;
  position: absolute;
  vertical-align: middle;;
  top: 40px;
  right: 50px;
  z-index: 1;
}
.video {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: -1;
}

.vital-sign-hud {
  margin-left: 48px;
  margin-right: 48px;
}

#lify-logo {
  position: absolute;
  width: 124px;
  height: 124px;
  top: 37px;
  left: 38px;
}

#panoptic-logo {
  position: absolute;
  width: 197px;
  left: 175px;
  top: 79px;
}
.system-message {
  margin-top: 20%;
}
.instruction-box{
  position: absolute;
  top: 10%;
  width: calc(100% - 87px);
  font-size: 48px;
  text-align: center;
  color: #FF3535;
  text-shadow: 1px 1px white;
  margin-left: auto;
  margin-right: auto;
  z-index: 1;
}
#video-container {
  overflow: hidden;
  height: 98vh;
  z-index: 0;
}
.running-message {
  margin-top: 20%;
  font-size: 48px;
  color: white;
  text-align: center;
  text-shadow: 0px 0px 5px #000000;
}
.disclaimer {
  position: absolute;
  left: 40%;
  right: 40%;
  top: 20px;
  padding: 10px;
  color: black;
  background-color: lightgrey;
  opacity: 0.8;
  font-size: 0.9em;
  text-align: center;
}
.vsButton{
  overflow: hidden;
}
.copyright {
  position: relative;
  font-size: 14px;
  bottom: 3%;
  left: 50%;
  transform: translate(-50%, 0);
}
#icon-mobile {
  display: none;
}
.huds-container {
  position: absolute;
  top: 208px;
  width: 100%;
  height: 70%;
  overflow-y: scroll;
  overscroll-behavior: none;
  /* height: fit-content; */
}
.huds-container::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.huds-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.disableBox {
  pointer-events: none;
  opacity: 0.7;
}
.info-bar {
  position: absolute;
  bottom: 5%;
  left: calc(50% - 64px)
}
.info-button {
  width: 150px;
  height: 75px;
  min-height: 50px;
  font-size: large;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  margin-top: auto;
  margin-bottom: auto;
}

.vitals-row {
  margin-left: 3rem;
  margin-right: 3rem;
}

@media (max-width: 1024px) {
  .huds-container {
    top: 500px;
    height: 45%;
  }
}

@media (max-width: 600px) {
  .buttons {
    right: 20px;
    transform: scale(0.9);
  }

  #lify-logo {
    width: 100px;
    height: 100px;
  }

  #panoptic-logo {
    width: 177px;
    left: 155px;
    top: 50px;
  }

  .huds-container {
    top: 350px;
  }

  .vitals-row {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .info-bar {
    left: calc(50% - 48px);
  }
}
</style>
