<template>
  <div class="face-mesh-container" ref="container">
    <canvas ref='canvas' :style="style"/>
  </div>
</template>

<script>
import { FACEMESH_TESSELATION } from '@mediapipe/face_mesh'
import { drawConnectors } from '@mediapipe/drawing_utils'
import adapter from 'webrtc-adapter'

export default {
  name: 'face-mesh-view',
  props: {'result': Object, 'aspectRatio': Number, videoId: String},
  setup() {
    return {
      style: {}
    }
  },
  data() {
    return {
      transform: "scaleX(-1)"
    }
  },
  watch: {
    result() {
      this.updateStyle()
      this.redraw()
    },
    aspectRatio() {
      this.updateStyle()
      this.redraw()
    },
    videoId() {
      this.changeTransform();
    }
  },
  methods: {    
    changeTransform() {
          const browser = adapter.browserDetails.browser;
          var facingMode;
          if(browser == 'firefox') {
          facingMode = document.getElementsByTagName('video')[0].srcObject.getVideoTracks()[0].getSettings().facingMode;
          }
          else {
          facingMode = document.getElementsByTagName('video')[0].srcObject.getVideoTracks()[0].getCapabilities().facingMode;
          }
          if(facingMode && facingMode[0] === 'environment') {
              this.transform = 'none';
          } else {
              this.transform = 'scaleX(-1)';
          }
    },
    updateStyle() {
      const video = document.getElementsByTagName('video')[0].srcObject.getVideoTracks()[0];
      if (video) {
        var height = video.getSettings().height;
        var width = video.getSettings().width;
        var aspectRatio = width / height;
      }
      let style = {
        left: '0%',
        top: '0%',
        aspectRatio: aspectRatio
      }
      let containerAspectRatio = this.$refs.container.clientWidth/this.$refs.container.clientHeight
      if (containerAspectRatio<aspectRatio) {
        style.left = `${ (containerAspectRatio-aspectRatio)/containerAspectRatio/2*100 }%`
        style.height = '100%'
      } else {
        style.top = `${ (1/containerAspectRatio-1/aspectRatio )*containerAspectRatio/2*100 }%`
        style.width = '100%'
      }
      this.style = style
    },
    redraw() {
      let canvas = this.$refs.canvas
      const ctx = canvas.getContext('2d');

      ctx.save()
      ctx.clearRect(0, 0, canvas.width, canvas.height)
      drawConnectors(
        ctx,
        this.result.landmarks,
        FACEMESH_TESSELATION,
        {
          color: "lightgreen",
          lineWidth: .2,
        }
      )
      ctx.restore()
    }
  },
}
</script>

<style>
.face-mesh-container {
  width: 100%;
  height: 100%;
    position: absolute;
    left: 0px;
    top: 0px;
    z-index: 0;
}
canvas {
    -webkit-transform: v-bind(transform);
    transform: v-bind(transform);
    position: absolute;
}
 
@import './face-mesh-view.css';

</style>