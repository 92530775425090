<template>
    <data-hud class="data-hud" title="BMI" unit="" :data="data"/>
</template>

<script>
import DataHud from "./data-hud.vue"

export default
  {
    name: 'bmi',
    props: {
        data: Number
    },
    setup() {
    },
    components: { DataHud }
}
</script>

<style scoped>
.data-hud {
    position: absolute;
    right: 48px;
    top: 930px;
}
</style>


