import { FaceMesh } from '@mediapipe/face_mesh'

function landmarksToFaceBox(landmarks) {

    let left = 1.0
    let top = 1.0
    let right = 0
    let bottom = 0
  
    landmarks.forEach( (landmark) => {
      left = Math.min(landmark.x, left)
      top = Math.min(landmark.y, top)
      right = Math.max(landmark.x, right)
      bottom = Math.max(landmark.y, bottom)
    })
  
    return {
      xCenter: left + (right - left)/2,
      yCenter: top + (bottom - top)/2,
      width: right - left,
      height: bottom - top
    }
  
  }
  
  export function FaceMeshDetection( onDetected ) {
  
      const faceMesh = new FaceMesh({
        locateFile: (file) => {
          return `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`
        }
      })
  
      faceMesh.onResults( (results) => {
        // console.log(results);
          if (results.multiFaceLandmarks === undefined) {
              return
          }
          const landmarks = results.multiFaceLandmarks[0]
          if (!landmarks || landmarks.length === 0) {
            return
          }
          let faceBox = landmarksToFaceBox(landmarks)
          onDetected({faceBox, landmarks, videoFrame:results.image})
      })
  
      return faceMesh
  
  }