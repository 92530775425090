<template>
    <div class="quiz" v-for="(content, index) in contents" :key="index">
        <card-content v-if="number == index && (index+1  == contents.length ? scanFinished : !quizAnswer )" :title="title" :subtitle="subtitle" :content="content" :number="index" @nextQuestion="nextQuestion" @addResult="addResult" :last="index == contents.length-1"/>
    </div>
</template>

<script>
import CardContent from "./card-content.vue";
import { newUserManager } from "../../user/company-user-manager.ts";

export default {
    setup() {
    },
    props: {
        scanFinished: Boolean,
        number: Number
    },
    components: {
        CardContent
    },
    computed: {
        quizAnswer() {
            return newUserManager.currentUser.currentUser.quizAnswer;
        }
    },
    emits: ['displayResult', 'nextCard'],
    data() {
        return {
            title: "Lify Personalized Wellness Beverages",
            subtitle: "Recommendations tailormade just for you",
            contents: [
                {
                    question: 'Hi there. 👋 Let us learn more about you while scanning in process...',
                    options: ['Sure, start now!'],
                    image: "./assets/lify.svg"
                },
                {
                    question: "On an average day, you feel mostly..",
                    options: ['Calm, relaxed', 'Stressed, tired', 'Quick-tempered'],
                    result: [
                        [],
                        ['energising', 'stress-relief'],
                        ['stress-relief']
                    ]
                },
                {
                    question: "Your sleep is...",
                    options: ['Good and uninterrupted', 'Okay in general', 'Light with sleepless nights'],
                    result: [
                        [],
                        [],
                        ['stress-relief']
                    ]
                },
                {
                    question: "Do you always feel tired after getting up",
                    options: ['On most days', 'On some days', 'Rarely'],
                    result: [
                        ['energising'],
                        ['energising'],
                        []
                    ]
                },
                {
                    question: "Do you catch cold or flu easily?",
                    options: ['Yes (once a month)', 'Sometimes (once in 3 months)', 'Rarely (once a year)'],
                    result: [
                        ['immunity'],
                        ['immunity'],
                        []
                    ]
                },
                {
                    question: "Your appetite and body digestion is..",
                    options: ['Good, with regular meal times and good bowel movements', ' Constipation with loose stools', 'Poor appetite with bloating stomach', 'None of the above'],
                    result: [
                        [],
                        ['detox'],
                        ['detox'],
                        []
                    ]
                },
                {
                    question: 'Your Lify recommendations are in! Click below to see the results.',
                    options: ['See Results!'],
                    image: "./assets/lify.svg"
                },
            ],
            result: [],
        }
    },
    watch: {
        number() {
            if(this.number == this.contents.length) {
                this.$emit('displayResult');
            }
            if (this.number == this.contents.length - 1) {
                newUserManager.currentUser.currentUser.setQuizAnswers(this.result);
            }
        },
    },
    methods: {
        nextQuestion() {
            this.$emit('nextCard');
        },
        addResult(newResult) {
            this.result.push(newResult)
        }
    }
}
</script>

<style scoped>
.quiz {
  display: flex;
  width: calc(100% - 580px);
  justify-content: center;
  position: absolute;
  top: 37%;
  left: 290px;
  opacity: 0.9;
}

@media (max-width: 1024px) {
    .quiz {
        left: 0px;
        width: 100%;
        top: 250px;
    }
}

@media (max-width: 600px) {
    .quiz {
        top: 150px;
    }
}

</style>