import _ from "lodash";
import { cloudBackend } from './user-manager-definitions'
import type { companyUser, companySettings, scanResults, User, Health,   } from './user-definitions'
import { BaseCompanyUser } from './user-base'
import { generateEmptyCompanySettings, constructVitalSignsFromHealth } from './user_utilities.js'


export class LocalUser extends BaseCompanyUser implements companyUser {
    
    declare UID: string | undefined
    declare companySettings: companySettings | undefined
    declare currentUser: User | undefined
    declare scanResults: scanResults | undefined
    userType: cloudBackend = cloudBackend.local;

    name: string | undefined = "Guest"
    // declare userId: string | undefined
    // declare userSettings: userSettings | undefined
    // declare lastHealth: Health | undefined
    // declare lastVitalSigns: VitalSign | undefined
    // declare oneDayData?: Health[] | undefined
    // declare oneWeekData?: VitalSignOld[] | undefined
    // declare oneMonthData?: VitalSignOld[] | undefined

    constructor() {
        super();
        // clear data store locally for the last user
        window.localStorage.clear();
    }
    async getCompanySettings(): Promise<companySettings | undefined> {
        if (!this.companySettings) {
            if (window.localStorage.companySettings) {
                this.companySettings = JSON.parse(window.localStorage.companySettings);
            } else {
                this.companySettings = generateEmptyCompanySettings();
                this.UID = this.companySettings.UID;
            }
        } 
        // if (this.companySettings && this.companySettings.name) { this.name = `${this.userSettings.name} (Guest)` }
        return this.companySettings
    }
    async setCompanySettings(newCompanySettings: companySettings): Promise<any> {

        // TODO: save to local
        this.companySettings = newCompanySettings;
        window.localStorage.companySettings = JSON.stringify(newCompanySettings);

        console.log("updated company settings: ", this.companySettings);
        // if (this.userSettings && this.userSettings.name) { this.name = `${this.userSettings.name} (Guest)` }
        return true
    }
    async loadData(): Promise<void> {
        // throw new Error("Method not implemented.")
    }
    async login(): Promise<void> { }
    async logout(): Promise<void> { 
        // clear user data store locally
        window.localStorage.clear();
    }
    async changePassword(oldPassword: string, newPassword: string): Promise<boolean> {
        return true
    }
    async ready(): Promise<void> { 
        const timeout = 300; // ms
        let promise = new Promise((resolve) => setTimeout(resolve, timeout));
        await promise;
    }
    async _uploadScanResults(): Promise<void> {
        let doc = {
            vital_sign: this.currentUser?.lastVitalSigns,
            date: new Date(),
        };
        let vitalSigns;
        if (!window.sessionStorage.vitalSigns) {
            vitalSigns = [doc];
        } else {
            vitalSigns = JSON.parse(window.sessionStorage.vitalSigns);
            vitalSigns.push(doc);
        }
        // this.oneMonthData[this.oneMonthData?.length-1] = this.lastVitalSigns
        // this.oneMonthData?.slice(-1)[0] = this.lastVitalSigns
        window.sessionStorage.vitalSigns = JSON.stringify(vitalSigns);
        return;
    }
    uploadScanResults = _.debounce(this._uploadScanResults, 5000);

    setHealth(health: Health): void {
        throw new Error("Method not implemented.")
    }
    
    // async getUserSettings(): Promise<userSettings | undefined> {

    //     if (!this.userSettings) {
    //         if (window.localStorage.userSettings) {
    //             this.userSettings = JSON.parse(window.localStorage.userSettings);
    //         } else {
    //             this.userSettings = generateEmptyUserSettingsObject();
    //             this.userId = this.userSettings.userId;
    //         }
    //     } 
    //     if (this.userSettings && this.userSettings.name) { this.name = `${this.userSettings.name} (Guest)` }
    //     return this.userSettings
    // }
    // async setUserSettings(newUserSettings: userSettings): Promise<any> {
    //     this.userSettings = newUserSettings;

    //     // TODO: save to local
    //     console.log("updating user settings: ", this.userSettings);
    //     this.userSettings = newUserSettings;
    //     window.localStorage.userSettings = JSON.stringify(newUserSettings);

    //     if (this.userSettings && this.userSettings.name) { this.name = `${this.userSettings.name} (Guest)` }
    //     return true
    // }

    // async _uploadHealth(): Promise<void> {
    //     // console.log("user.uploadHealth: ", this.lastHealth)
    //     // load the latest health into the pass health data
    //     if (this.lastVitalSigns) {
    //         if (this.oneMonthData) {
    //             this.oneMonthData[this.oneMonthData.length - 1] = this.lastVitalSigns;
    //         }
    //         if (this.oneWeekData) {
    //             this.oneWeekData[this.oneWeekData.length - 1] = this.lastVitalSigns;
    //         }
    //         if (this.oneDayData && this.lastHealth) {
    //             this.oneDayData.push({
    //                 datetime: this.lastHealth.datetime,
    //                 vitalSigns: this.lastVitalSigns,
    //             })
    //         }
    //     }

    //     let doc = {
    //         vital_sign: this.lastVitalSigns,
    //         date: new Date(),
    //     };
    //     let vitalSigns;
    //     if (!window.sessionStorage.vitalSigns) {
    //         vitalSigns = [doc];
    //     } else {
    //         vitalSigns = JSON.parse(window.sessionStorage.vitalSigns);
    //         vitalSigns.push(doc);
    //     }
    //     // this.oneMonthData[this.oneMonthData?.length-1] = this.lastVitalSigns
    //     // this.oneMonthData?.slice(-1)[0] = this.lastVitalSigns
    //     window.sessionStorage.vitalSigns = JSON.stringify(vitalSigns);
    //     return;
    // }
    // uploadHealth = _.debounce(this._uploadHealth, 5000);

    // setHealth(newHealth: Health): void {
    //     if (newHealth === undefined) {
    //         return;
    //     }
    //     let { vs, results } = constructVitalSignsFromHealth(newHealth, true)  // results are newHealth without element that is undefined 

    //     this.lastVitalSigns = vs;
    //     if (results) {
    //         this.lastHealth = results;
    //         if (this.lastHealth) {
    //             this.lastHealth.datetime = new Date();
    //             this.lastHealth.userId = this.userId;
    //             this.lastHealth.location = this.userSettings?.countries;
    //         }
    //     }
    //     console.log(
    //         `%cHeart rate: ${newHealth.vitalSigns.heartRate}bpm. setHealth:`, 
    //         "color: green", 
    //         this.lastHealth, 
    //         this.lastVitalSigns
    //     );
        
    // }

}