import { companyUser } from "./user-definitions"


export enum cloudBackend {
    local = 0,
    firebase = 1,
    aws = 2,
    alibabacloud = 3,
}

export interface UserManagerInterface {
    currentUser: companyUser | undefined
    login(backend: cloudBackend): Promise<void>
    ready(backend: cloudBackend): Promise<void>
    logout(): Promise<void>
}