<template>
    <div class="hud-container data-hud">
        <div class="container h-100 pt-4">
            <div class="row w-100 m-0 title">
                Your Overall Wellness
            </div>
            <div class="row w-100 m-0 description">
                This wellness score is calculated through a weighted score of your core vital signs and mental stress level.
            </div>
            <div class="row w-100 m-0 my-3 justify-content-center">
                <div class="chart">
                    <div class="progress">
                         <div class="progress-bar" role="progressbar" :style="`width: ${data}%`" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <div class="col value">
                        {{data.toFixed(0)}}%
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useI18n } from 'vue-i18n';

export default
  {
    name: 'wellness',
    props: {
        data: Number
    },
    setup() {
    const { t, locale } = useI18n()
    return {t, locale}
    },
}
</script>

<style scoped>
.data-hud {
    position: relative;
}
.hud-container {
    font-family: "Montserrat", sans-serif;

    width: 242px;
    height: 278px;
    background: #FE805C;
    border-radius: 24px;
    text-align: center;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
.progress-bar {
    background-color: #FE805C;
    border-radius: 10px;
}
.progress {
    border-radius: 10px;
    width: 80%;
}
.title{
    padding: 0 35px;
    font-weight: 700;
    font-size: 24px;
    color: white;
}
.description {
    color: white;
    text-align: justify;
    padding: 0 25px;
    font-size: 12px;
}
.chart{
    background-color: white;
    width: 220px;
    height: 51px;
    border-radius: 91px;
    display: flex;
    align-items: center;
}
.value {
    padding-left: 10px;
    font-size: 16px;
    font-weight: 600;
}
@media (max-width: 600px) {
    .hud-container {
        width: 200px;
    }
    .title {
        font-size: 20px;
        padding: 0 15px;
    }
    .chart {
        width: 180px;
    }
    .description {
        padding: 0 15px;
    }
}
</style>


