<template>
<div id="overlay" v-if="!auth">
  <div class="modal-dialog" role="document" id="text">
    <div class="modal-content">
      <div class="modal-header">
        <img id="logo" src="../home/assets/panoptic_ai.svg" />
      </div>
      <div class="modal-body">
        <div class="row">
          <label for="password">Password:</label>
        </div>
        <div class="row">
          <input 
            id="passwordInput" 
            type="password" 
            v-model="password" 
            v-on:keyup.enter="checkPassword"
            placeholder="Enter access password"
          >
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary login-button" @click="checkPassword">Login</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {inject} from 'vue';
// import { db } from "../../firebase.js";
// import { doc, getDoc } from 'firebase/firestore';

  export default {
    name: 'web-login',
    setup() {
      const {auth, updateAuth} = inject('auth');
      const webPassword = 'lifywellness'
      return {
        auth, updateAuth, webPassword
      }
    },
    data() {
      return {
        password: '',
      }
    },
    methods: {
      async checkPassword() {
        // get password from database
        // console.log("%c < query firestore > web-login webAccess login checkPassword", "color: #A0A0A0")
        // const loginRef = doc(db, "webAccess", "login");
        // const login = await getDoc(loginRef);
        
        if (this.password == this.webPassword) {
          this.updateAuth();
        }

      }
    }
  }
</script>

<style scoped>
#overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 2;
  cursor: pointer;
}
#logo {
  width: 70%;
  margin: auto auto;
}
input {
  max-width: 99%;
}
.modal-dialog {
  background-color: white;
  border-radius: 15px;
  padding: 10px;
  max-width: 80%;
  box-shadow: 2px 2px rgba(40, 40, 40, .5);
}
.modal-header {
  margin: 10px 0px;
}
.modal-body {
  margin: 10px 0px;
}
.modal-footer {
  margin: 10px auto;
  width: 90%;
}
#passwordInput {
  max-width: 90%;
  margin: auto auto;
}
#text{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
}
.login-button {
  border-radius: 15px;
  text-align: center;
  margin: auto;
}
@media (max-width: 768px) {
  .modal-dialog {
    min-width: 80%;
    font-size: small;
  }
  .login-button {
    font-size: small;
  }
}
</style>