export default {
    simulation: false,
    status: 'running',
    handleResponse(response) {
        if (response.status !== 200) {
            return response.json().then( json => {
                if (json.message) {
                    throw new Error(json.message);
                } else {
                    throw new Error(JSON.stringify(json));
                }
            });
        } else {
            return Promise.resolve(response);
        }

    },
    startSystem() {
        if (this.simulation) {
            return new Promise( (resolve) => {
                setTimeout(resolve, 1000);
            }).then( () => {
                this.status = 'running';
            });
        } else {
            return fetch('/api/start')
            .then ( response => {
                return this.handleResponse(response);
            });
        }
    },
    stopSystem() {
        if (this.simulation) {
            return new Promise( (resolve) => {
                setTimeout(resolve, 1000);
            }).then( () => {
                this.status = 'standby';
            });
        } else {
            return fetch('/api/stop')
            .then( r => this.handleResponse(r) );
        }

    },
    refreshStatus() {
        if (this.simulation) {
            return Promise.resolve({
                fps: 30,
                status: this.status
            });
        } else {
            return fetch("/api/status").then( r => r.json() );
        }
    },
    startGettingVS() {
        return fetch('/api/start_getting_vigal_sign');
    }
};