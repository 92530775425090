<template>
    <div class="information-box-container">
        <div 
            v-if="enable" 
            class="instruction-box"
        >
            <p class="text">
                {{ t("instruction.stay_still") }}
            </p>
        </div>
    </div>
</template>

<script>
import { useI18n } from "vue-i18n";

export default
  {
    name: 'information-box',
    props: {
        enable: { default: false, type: Boolean },
    },
    setup() {
        const { t, locale } = useI18n();
        return { t, locale }
    },
    mounted() {
    },
    methods: {
    },
    computed: {
        isEnabled() {
            if (this.enable) {
                return "enabled"
            } else {
                return "disabled"
            }
        }
    },
}
</script>

<style>
.information-box-container {
    display: flex;
    width: 100%;
    justify-items: center;
    justify-content: center;
    position: absolute;
    padding: auto 20px;
    top: 115px;
}
.instruction-box {
    width: 330px;
    height: 51px;
    background-color: white;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}
.text {
    font-size: 15px;
    font-family: 'Montserrat', sans-serif;
    margin: auto 28px;
    font-weight: 400;
}

@media (max-width: 600px) {
    .information-box-container {
        top: 95px;
    }

    .instruction-box {
        width: 290px;
        height: 43px;
    }

    .text {
        font-size: 14px;
    }
}

</style>