<template>
  <div class="container d-flex flex-column text-center">
    <!-- <img id="logo" src="../sidebar/assets/icon.svg" /> -->
    <!-- <img id="panoptic-ai" src="../home/assets/panoptic_ai.svg" /> -->
    <div class="login-console w-100">
      <authenticator :hide-sign-up="true" :login-mechanisms="['email']" :form-fields="formFields">
        <template v-slot:sign-in-header>
          <h3
            class="amplify-heading"
          >
            Welcome
          </h3>
        </template>
      </authenticator>

      <a class="my-1" v-if="isReady" href="#" @click="loginAsGuest">{{
        t("guest_signin")
      }}</a>
    </div>
    <div class="copyright text-center row">
      <img id="panoptic-ai" src="../home/assets/panoptic_ai_vitals.png" />
      <div> {{ appTitle }} </div>
      <div>{{ t("copyright") }}</div>
    </div>
  </div>
  
</template>

<script>
import { newUserManager } from "../../user/company-user-manager.ts";
import { cloudBackend } from "../../user/company-user-manager-definitions.ts";
import { Authenticator, useAuthenticator } from "@aws-amplify/ui-vue";
import "@aws-amplify/ui-vue/styles.css";
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import "./aws-authenticator.css"
import { I18n } from 'aws-amplify';
import { translations } from '@aws-amplify/ui-vue';
import Logger from "@/common/logger"

I18n.putVocabularies(translations);

I18n.putVocabulariesForLanguage('en', {
  'Sign In': 'Login', // Tab header
  'Sign in': 'Login', // Button label
  Username: 'Enter your username', // Username label
});

export default {
  components: {
    Authenticator,
  },
  setup() {
    const { t, locale } = useI18n();
    const auth = useAuthenticator();
    const isReady = ref(false);

    const formFields = {
      signIn: {
        username: {
          placeholder: 'Enter your Email', 
          labelHidden: false,
          label: 'Email',
          required: true,
          order: 1,
        }, 
        password: {
          labelHidden: false,
          label: 'Password',
          placeholder: 'Enter your Password',
          isRequired: true,
          order: 2,
        },
      }
    }

    return {
      t,
      locale,
      auth,
      isReady,
      formFields
    };
  },
  async mounted() {
    let currentCloudBackend = cloudBackend.aws;

    Logger.log("aws-login mounted", newUserManager)
    // Handle redirection and function calls
    // User already logged in
    if (newUserManager.currentUser) {
      let currentUser = newUserManager.currentUser;
      // check if the user has logged in or not
      if (currentUser && currentUser.cloudInfo) {
        Logger.log("already logged in: ", currentUser);
        this.$router.push("/input-profile");
        return;
      }
      // log user in
      switch (currentUser.userType) {
        case cloudBackend.local: {
          break;
        }
        case cloudBackend.aws: {
          await newUserManager.login(cloudBackend.aws);
          Logger.log("login done");
          break;
        }
      }

      // Login complete, redirect to home
      Logger.log("logged in: ", newUserManager.currentUser);
      this.$router.push("/input-profile");
      return;
    } else {
      await newUserManager.ready(cloudBackend.aws);
      this.isReady = true && this.$root.debugMode;
      await newUserManager.login(currentCloudBackend);
      this.$router.push("/input-profile");
    }
  },
  methods: {
    loginAsGuest() {
      newUserManager.login(cloudBackend.local);
      this.$router.push("/home");
    },
  },
  computed: {
    appTitle() {
      return this.$root.$data.appTitle ? this.$root.$data.appTitle : ""
    }
  }
};
</script>

<style scoped>
.container{
  /* background: rgb(118, 118, 214) url("webappBackground.jpg") no-repeat; */
  background-image: linear-gradient(141.93deg, #007995 39.38%, #FFFFFF 101.52%);
  background-size: cover;
  background-position: center bottom;
  min-width: 100%;
  min-height: 100vh;
  height: 5px;
  align-items: center;
  overflow: scroll;
}
.login-console {
  margin: auto auto;
}
.amplify-heading{
  margin: auto auto;
  padding-top: 5%;
  font-size: large;
}
#panoptic-ai {
  margin: auto;
  width: 600px;
  max-width: 90%;
  /* background-color: rgba(255, 255, 255, 0.2); */
  /* border-radius: 10px; */
}
#logo {
  width: 80px;
  margin: 2em;
}
.copyright {
  margin: auto auto;
  font-size: medium;
  /* font-weight: bold; */
  width: 70%;
  border-radius: 5px;
}
@media (max-width: 768px) {
  .login-console {
    position: absolute;
    min-width: 90%;
    top: 5%;
    left: 50%;
    transform: translate(-50%, 0);
    margin: auto auto;
  }
  .copyright {
    font-size: x-small;
    min-width: 90%;
    bottom: 1%;
  }
}
</style>