<template>
    <data-hud class="data-hud" title="CVD Risk (Beta)" unit="%" :data="data"/>
</template>

<script>
import DataHud from "./data-hud.vue"

export default
  {
    name: 'cvd-risk-general',
    props: {
        data: Number
    },
    setup() {
    },
    components: { DataHud }
}
</script>

<style scoped>
.data-hud {
    position: absolute;
    left: 135px;
    top: 930px;
}
</style>


